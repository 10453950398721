<style lang="scss" scoped>
    ::v-deep {
        pre:not([class]) {
            white-space: pre-wrap;
            margin-bottom: 1.5em;
        }
    }

    .message {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: flex-end;
        flex-direction: column;
        padding: 20px 0;
        &.system {
            align-items: flex-start;
            flex-direction: row;
        }
        &.user {
            white-space: pre-wrap;

            & > div {
                background: #ddd;
                padding: 12px 24px;
                border-radius: 20px;
            }
        }
        &.system > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        > div {
            max-width: 80%;
        }
        &-content {
            max-width: 100%;
            overflow: auto;

            ::v-deep{
                table {
                    th, td {
                        padding-top: 8px;
                        padding-bottom: 8px;
                        border-bottom: 1px solid lightgray;
                    }

                    th:not(:last-of-type),
                    td:not(:last-of-type) {
                        padding-right: 24px;
                    }
                }
            }
        }
    }
    .message-space {
        height: 0;
        visibility: hidden;
        margin-top: -20px;
    }
    .input {
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
    }
    .my-editor {
        height: auto;
        background: #ddd;
        font-size: 14px;
        line-height: 1.5;
        margin-top: 8px;
        padding: 10px 16px;
        border-radius: 10px;
        margin-top: 8px;
    }
    .controls {
        display: flex;
        margin-top: 4px;
        justify-content: space-between;
    }
    .controls > div {
        display: flex;
        gap: 4px;
    }
    .pulse {
        opacity: 1;
        animation: pulse 3s 0s ease-in-out infinite;
    }
    @keyframes pulse {
        50% {
            opacity: .3;
        }
    }
</style>

<template>
    <div>
        <div class="message user">
            <div v-if="isManageMode">
                <div class="message-space">
                    {{ prompt }}
                </div>
                <v-textarea
                    v-model="prompt"
                    :placeholder="$t('copilot.userPrompt')"
                    rows="1"
                    auto-grow
                    hide-details />
            </div>
            <div v-else>
                {{ prompt }}
            </div>
        </div>
        <div class="message system">
            <img
                src="@/assets/logo-symbol.avif"
                :class="{ 'pulse': !message.chat.response }"
                height="32" />
            <div
                v-if="!message.chat.response"
                class="pulse font-weight-bold">
                . . .
            </div>
            <div v-else>
                <div
                    v-if="!isManageMode"
                    class="message-content"
                    v-html="mdToHtml">
                </div>
                <div v-if="message.chat.response.sqlText?.length || message.chat.response.errorText?.length">
                    <v-tabs>
                        <v-tab v-if="message.chat.response.sqlText?.length">
                            SQL
                        </v-tab>
                        <v-tab v-if="message.chat.response.errorText?.length && !isManageMode">
                            Error
                        </v-tab>
                        <v-tab-item v-if="message.chat.response.sqlText">
                            <prism-editor
                                v-model="sqlText"
                                class="code-editor"
                                :highlight="highlighter" />
                        </v-tab-item>
                        <v-tab-item v-if="message.chat.response.errorText">
                            {{ message.chat.response.errorText }}
                        </v-tab-item>
                    </v-tabs>
                </div>
                <div
                    v-if="isManageMode"
                    class="controls">
                    <div>
                        <v-btn
                            text
                            x-small
                            color="primary"
                            @click="toggleGoldenQuery">
                            <v-icon left>
                                mdi-bookmark-off
                            </v-icon>
                            {{ $t('copilot.removeGolden') }}
                        </v-btn>
                    </div>
                    <div
                        v-if="message.chat.response.sqlText?.length"
                        class="context">
                        <v-btn
                            text
                            x-small
                            color="primary"
                            @click="updateQuery">
                            <v-icon left>
                                mdi-content-save-outline
                            </v-icon>
                            {{ $t('save') }}
                        </v-btn>
                    </div>
                </div>
                <div
                    v-else
                    class="controls">
                    <div>
                        <v-btn
                            icon
                            x-small
                            :class="[{ 'success--text': message.feedback === 'LIKE' }]"
                            @click="submitFeedback('LIKE')">
                            <v-icon>mdi-thumb-up</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            x-small
                            :class="[{ 'error--text': message.feedback === 'DISLIKE' }]"
                            @click="submitFeedback('DISLIKE')">
                            <v-icon>mdi-thumb-down</v-icon>
                        </v-btn>
                        <v-tooltip
                            v-if="message.chat.response.sqlText?.length && selectedAgent.type === 'USER'"
                            max-width="300"
                            open-delay="400"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    text
                                    x-small
                                    :color="messageQuery ? 'primary' : null"
                                    v-on="on"
                                    @click="toggleGoldenQuery">
                                    <v-icon left>
                                        {{ messageQuery ? 'mdi-bookmark' : 'mdi-bookmark-outline' }}
                                    </v-icon>
                                    {{ $t('copilot.markGolden') }}
                                </v-btn>
                            </template>
                            <div>
                                {{ $t('copilot.markGoldenInfo') }}
                            </div>
                        </v-tooltip>
                        <v-tooltip
                            v-if="canAnalyseQuery"
                            max-width="300"
                            open-delay="400"
                            :disabled="isAnalyseQueryActive"
                            top>
                            <template #activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        text
                                        x-small
                                        color="primary"
                                        :loading="isAnalysingQuery"
                                        :disabled="!isAnalyseQueryActive"
                                        style="vertical-align: top;"
                                        @click="analyseQuery">
                                        <v-icon left>
                                            mdi-magnify-scan
                                        </v-icon>
                                        {{ $t('copilot.analyseQuery') }}
                                    </v-btn>
                                </div>
                            </template>
                            <div>
                                {{ $t('copilot.analyseQueryInfo') }}
                            </div>
                        </v-tooltip>
                        <v-tooltip
                            v-if="message.confidence"
                            max-width="300"
                            open-delay="400"
                            :disabled="!confidenceInfo.reason"
                            top>
                            <template #activator="{ on }">
                                <div v-on="on">
                                    <v-btn
                                        text
                                        plain
                                        x-small
                                        :color="confidenceInfo.color"
                                        :ripple="false"
                                        class="cursor-help"
                                        style="vertical-align: top;">
                                        <v-icon left>
                                            mdi-bullseye-arrow
                                        </v-icon>
                                        <span class="pr-1">{{ confidenceInfo.label }}</span>
                                        <v-icon
                                            x-small
                                            dense>
                                            mdi-help-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </template>
                            <div>
                                {{ confidenceInfo.reason }}
                            </div>
                        </v-tooltip>
                    </div>
                    <div
                        v-if="message.chat.response.sqlText?.length"
                        class="context">
                        <v-btn
                            text
                            x-small
                            color="primary"
                            :disabled="!hasSQLChanged"
                            @click="runQuery">
                            <v-icon left>
                                mdi-play-box
                            </v-icon>
                            {{ $t('copilot.runQuery') }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { marked } from 'marked'
    import Prism from 'prismjs'
    import { PrismEditor } from 'vue-prism-editor'

    export default {
        name: 'CopilotChatMessage',
        components: {
            PrismEditor,
        },
        props: {
            message: {
                type: Object,
                required: true,
            },
            sessionId: {
                type: String,
                required: true,
            },
            mode: {
                type: String,
                default: 'chat',
            },
        },
        data() {
            return {
                prompt: '',
                sqlText: '',
                isAnalysingQuery: false,
            }
        },
        computed: {
            ...mapGetters([
                'selectedAgent',
                'selectedChatSession'
            ]),
            isManageMode() {
                return this.mode === 'manage'
            },
            hasSQLChanged() {
                return this.sqlText !== this.message?.chat?.response?.sqlText?.trim()
            },
            messageQuery() {
                return this.selectedAgent.queries.find(query => query.originEntryId === this.message.id)
            },
            mdToHtml() {
                this.initializePrism()
                const renderer = this.initializeMarkedRenderer()
                const content = this.message?.chat?.response?.content?.trim() || ''
                const resArr = content.split('```') || []
                let mdhtml = ''
                resArr.forEach((item, index) => {
                    if ((content.startsWith('```') && index % 2 !== 0) || index % 2 === 0) {
                        mdhtml += marked.parse(item, { renderer, })
                    } else {
                        const codeArr = item.split('\n')
                        let lang = codeArr[0]
                        lang = Prism.languages[lang] ? lang : 'markup'
                        codeArr.shift()
                        const code = codeArr.join('\n')
                        mdhtml += this.getPrismFormattedCode(code, lang)
                    }
                })
                return mdhtml
            },
            canAnalyseQuery() {
                // Keep on dev/test for now
                return !this.$help.isProdEnv() &&
                    this.message.chat.response.sqlText?.length &&
                    this.selectedAgent.type === 'USER' &&
                    !this.messageQuery &&
                    !this.message.confidence
            },
            isAnalyseQueryActive() {
                return this.selectedAgent?.queries?.length > 0
            },
            confidenceInfo() {
                const info = {
                    reason: null,
                    label: null,
                    color: null,
                }
                if (this.message.confidence) {
                    info.reason = this.message.confidence.reason
                    if (this.message.confidence.confidence >= 4) {
                        info.label = this.$t('copilot.confidence.high')
                        info.color = 'success'
                    } else if (this.message.confidence.confidence >= 2) {
                        info.label = this.$t('copilot.confidence.med')
                        info.color = 'warning'
                    } else {
                        info.label = this.$t('copilot.confidence.low')
                        info.color = 'error'
                    }
                }
                return info
            },
        },
        watch: {
            'message.chat.response.sqlText': {
                handler() {
                    this.sqlText = this.message?.chat?.response?.sqlText?.trim() || ''
                },
                immediate: true,
            },
            'message.chat.prompt': {
                handler() {
                    this.prompt = this.message?.chat?.prompt?.trim() || ''
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions([
                'submitChatFeedback',
                'addAgentQuery',
                'updateAgentQuery',
                'removeAgentQuery',
                'clearTempAgentQueries',
                'refreshAgents',
                'analyseChatMessageQuery'
            ]),
            highlighter(code) {
                const lang = 'sql'
                return Prism.highlight(code, Prism.languages[lang], lang)
            },
            initializePrism() {
                Prism.plugins.NormalizeWhitespace.setDefaults({
                    'remove-trailing': true,
                    'remove-indent': true,
                    'left-trim': true,
                    'right-trim': true,
                })
                setTimeout(() => {
                    Prism.highlightAll()
                }, 0)
            },
            initializeMarkedRenderer() {
                const renderer = new marked.Renderer()
                renderer.link = ({ href, title, tokens, }) => {
                    const text = renderer.parser.parseInline(tokens)
                    const cleanHref = this.cleanUrl(href)
                    if (cleanHref === null) {
                        return text
                    }
                    href = cleanHref
                    let out = `<a target="_blank" href="${href}"`
                    if (title) {
                        out += ` title="${escape(title)}"`
                    }
                    out += `>${text}</a>`
                    return out
                }
                return renderer
            },
            getPrismFormattedCode(code, lang) {
                let codeBlock = Prism.highlight(code, Prism.languages[lang], lang)
                return `<pre class='language-${lang}'><code class='language-${lang}'>${codeBlock}</code></pre>`
            },
            cleanUrl(href) {
                try {
                    return encodeURI(href).replace(/%25/g, '%')
                }
                catch {
                    return null
                }
            },
            runQuery() {
                this.$emit('runQuery', this.sqlText)
            },
            async updateQuery() {
                if (!this.sqlText.length || !this.prompt.length) {
                    this.$store.commit('showMessage', { text: this.$t('errors.enterQueryInfo'), type: this.$config.messageType.ERROR, })
                    return false
                }
                const chat = this.$lodash.cloneDeep(this.message.chat)
                chat.response.sqlText = this.sqlText.trim()
                chat.prompt = this.prompt.trim()
                if (this.message.id) {
                    await this.updateAgentQuery({
                        agentId: this.selectedAgent.id,
                        queryId: this.message.id,
                        query: {
                            chat,
                            originEntryId: null,
                        },
                    })
                } else {
                    await this.addAgentQuery({
                        agentId: this.selectedAgent.id,
                        type: 'GOLDEN',
                        chat,
                    })
                    await this.removeAgentQuery({
                        agentId: this.selectedAgent.id,
                        tempQueryId: this.message?.tempId,
                    })
                }
            },
            async submitFeedback(feedback) {
                await this.submitChatFeedback({
                    feedback: this.message.feedback === feedback ? null : feedback,
                    entryId: this.message.id,
                    chatSessionId: this.sessionId,
                })
            },
            async toggleGoldenQuery() {
                if (this.isManageMode || this.messageQuery) {
                    await this.removeAgentQuery({
                        agentId: this.selectedAgent.id,
                        queryId: this.isManageMode ? this.message.id : this.messageQuery.id,
                        tempQueryId: this.message?.tempId,
                    })
                } else {
                    await this.addAgentQuery({
                        agentId: this.selectedAgent.id,
                        sessionEntryId: this.message.id,
                        type: 'GOLDEN',
                    })
                }
                this.refreshAgents()
            },
            async analyseQuery() {
                this.isAnalysingQuery = true
                await this.analyseChatMessageQuery({
                    chatSessionId: this.selectedChatSession?.id,
                    entryId: this.message.id,
                })
                this.isAnalysingQuery = false
            },
        },
    }
</script>

<style lang="scss">
    .clickable-element:hover {
        cursor: pointer;
    }
    .highlight-instance-row {
        border: 2px solid #5db459 !important;
        z-index: 9;
    }
    .overview-wrapper {
        position: absolute;
        width: 320px;
        transition: all 0.5s;
        transition-delay: 0.25s;
    }
    .launch-button {
        background: $primary !important;
    }
    .launch-disabled {
        background: $grayed-out !important;
        color: #fff !important;
        span {
            color: #fff !important;
        }
    }
    .ls-readonly-panels.v-expansion-panels .v-expansion-panel-header {
        cursor: auto;
    }
</style>

<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-row
            class="wrapper-container">
            <v-col
                ref="options"
                cols="8">
                <div class="d-flex justify-space-between mb-10">
                    <div class="view-headline">
                        <span>{{ getLocaleFor("launchServiceTitle") }}</span>
                    </div>
                </div>
                <v-row>
                    <v-expansion-panels
                        v-model="panel"
                        class="ls-readonly-panels mb-6"
                        multiple
                        accordion
                        readonly>
                        <TopologySelection
                            :is-topology-selected="isTopologySelected"
                            @onRequirementOfDBSelect="onRequirementOfDBSelect"
                            @onTopologySelect="onTopologySelect" />
                        <CloudProviderSelection
                            v-if="isTopologySelected"
                            :get-locale-for="getLocaleFor"
                            :location="location"
                            :provider-type="providerType"
                            :current-tier="currentTier"
                            :topology="topologyName"
                            :is-disabled="shouldDisableCloudProvider"
                            @onCloudProviderSelect="onCloudProviderSelect"
                            @onRegionSelect="onRegionSelect"
                            @on-zone-select="onZoneSelection" />
                        <ConfigurationSelection
                            v-show="canConfigure"
                            :instance-data="filteredInstances"
                            :replicas="replicas"
                            :default-storage-size="selectedInstanceDisk"
                            :is-replica-disabled="isReplicaDisabled"
                            :provider-type="providerType"
                            :volume-types="volumeTypes"
                            :default-iops-volume-type="iopsVolumeType"
                            :default-iops-volume-size="iopsVolumeSize"
                            :current-tier="currentTier"
                            :topology-range="topologyNodeRange"
                            :show-config-panel="showConfigPanel"
                            :topology="topologyName"
                            :storage-range="storageSizes"
                            :instance-cost="filteredInstanceCosts"
                            :is-disabled="shouldDisableInstanceSelection"
                            :show-replica-widget="shouldHaveReplicaWidget"
                            :selected-instance="selectedInstance"
                            :max-scale-instances="filteredMaxscaleInstances"
                            @onInstanceConfigSelect="onInstanceConfigSelect"
                            @onStorageSelect="onStorageSelect"
                            @onReplicaChange="onReplicaChange"
                            @onVolumeSizeChange="changeIopsVolumeSize"
                            @onVolumeTypeChange="changeIopsVolumeType"
                            @on-auto-txn-storage-toggle="onStorageAutoToggle"
                            @on-auto-node-toggle="onNodeAutoToggle"
                            @on-node-scale-type-selected="onAutoNodeScaleTypeSelection"
                            @on-node-scale-value-selected="onAutoNodeScaleValueSelection"
                            @on-txn-storage-selected="onAutoStorageScaleValueSelection"
                            @on-maxscale-toggle="onMaxScaleToggle"
                            @on-maxscale-node-selection="setMaxScaleNode"
                            @on-maxscale-size-selection="setMaxScaleSize"
                            @on-iops-change="onIopsChange"
                            @on-throughput-change="onThroughputChange" />
                        <ServerSelection
                            :get-locale-for="getLocaleFor"
                            :server-list="serverVersions"
                            :default-service-name="serviceName"
                            :is-disabled="shouldDisableServiceAttribute"
                            :region="location"
                            :can-change-version="canChangeServerVersion"
                            :is-power-tier-user="isPowerTierUser"
                            @onMariaDBServerSelect="onMariaDBServerSelect"
                            @onServiceNameEntered="onServiceNameEntered"
                            @onMaintenanceWindowSelect="onMaintenanceWindowSelect" />
                        <OptionsSelection
                            v-show="additionalOptions.length"
                            :get-locale-for="getLocaleFor"
                            :options="additionalOptions"
                            :is-disabled="shouldDisableOtherAttributes"
                            @optionChange="updateAdditionalOption" />
                        <SecuritySelectionV2
                            v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                            v-show="canSetSecurity"
                            :provider-type="providerType"
                            :is-s-sl-enabled="isSSlEnabled"
                            :show-ssl="canToggleSSL"
                            :is-psc-supported="canUsePSC"
                            :is-disabled="shouldDisableSecurity"
                            @onSSLToggle="onSSLToggle"
                            @on-service-security-select="handleServiceSecurity"
                            @on-secondary-endpoint-toggled="handleSecondaryEndpointAction"
                            @on-secondary-endpoint-current-ip-select="handleSecondaryEndpointIpSelect" />
                        <SecuritySelection
                            v-else
                            v-show="canSetSecurity"
                            :provider-type="providerType"
                            :is-s-sl-enabled="isSSlEnabled"
                            :has-ssl="canToggleSSL"
                            :is-psc-supported="canUsePSC"
                            :is-disabled="shouldDisableSecurity"
                            @onSSLToggle="onSSLToggle"
                            @on-service-security-select="handleServiceSecurity" />
                    </v-expansion-panels>
                </v-row>
            </v-col>
            <v-col
                class="ml-4 py-0"
                style="position: relative;">
                <div
                    ref="summary"
                    class="overview-wrapper"
                    :style="{
                        top: overviewOffset + 'px' }">
                    <SelectedServiceOverview
                        :selected-instance="selectedInstance"
                        :selected-server="selectedServer"
                        :service-name="serviceName"
                        :is-s-s-l-enabled="isSSlEnabled"
                        :selected-instance-disk="selectedInstanceDisk"
                        :topology-type="topologyType"
                        :topology-icon="topologyIcon"
                        :topology="topologyName"
                        :provider-type="providerType"
                        :location="location"
                        :location-label="locationLabel"
                        :current-location-icon="currentLocationIcon"
                        :single-zone="singleZone"
                        :unit-costs="unitCosts"
                        :launch-enabled="!shouldDisableLaunch"
                        :replicas="replicas"
                        :iops-volume-size="iopsVolumeSize"
                        :iops-volume-type="iopsVolumeType"
                        :service-cost-estimate="serviceCostEstimate" />
                    <div>
                        <v-btn
                            :class="['launch-button text-none', { 'launch-disabled': shouldDisableLaunch }]"
                            :dark="!shouldDisableLaunch"
                            :disabled="shouldDisableLaunch"
                            block
                            x-large
                            rounded
                            @click="tryLaunch">
                            <v-icon
                                v-if="!shouldPromptForPayment && !shouldRequestFreeTrial"
                                size="30"
                                class="mr-3">
                                $vuetify.icons.launchRocket
                            </v-icon>
                            <span>{{
                                shouldRequestFreeTrial ?
                                    getLocaleFor('requestFreeTrial') :
                                    shouldPromptForPayment ?
                                        getLocaleFor('addPaymentAndLaunch') :
                                        getLocaleFor('launchService')
                            }}</span>
                            <v-progress-circular
                                v-if="isServiceCreationInProgress"
                                class="ml-4"
                                :width="5"
                                :size="20"
                                indeterminate />
                        </v-btn>
                        <div
                            v-if="isMaxServerlessRuleViolated"
                            class="pa-2 text-caption text-center">
                            {{ getLocaleFor('maxFreeServerlessMessage') }}
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>

        <div v-if="showPaymentDialog">
            <PaymentModal
                :payment-profile="paymentProfile"
                :legal-entity-identifier="legalEntityIdentifier"
                :primary-contact-country="billingRecord.primaryContactCountry"
                @on-update-profile="handleProfileUpdate"
                @on-payment-close="handleProfileClose" />
        </div>
        <ConfirmationModal
            v-if="isFreeTrialActive"
            ref="confirmFreeTrialModal"
            title=""
            confirm-label="continue"
            :on-confirm="launchService">
            <template #content>
                <template v-if="!!freeCreditLeft">
                    <template v-if="isServerlessAvailable">
                        {{ $t('launchService.freeTrialAndSCUDisclaimer', [freeCreditLeft, freeSCUHoursLeft]) }}
                    </template>
                    <template v-else>
                        {{ $t('launchService.freeTrialDisclaimer', [freeCreditLeft]) }}
                    </template>
                </template>
                <template v-else-if="isServerlessAvailable">
                    {{ $t('launchService.freeTrialSCUDisclaimer', [freeSCUHoursLeft]) }}
                </template>
                {{ $t('launchService.freeTrialExhaustNotice') }}
                <br /><br />
                {{ $t('launchService.freeTrialEnjoy') }}
                <a
                    :href="`mailto:${$config.CONTACT_EMAIL}`"
                    class="text-lowercase">{{ $t('launchService.contact-us') }}</a>,
                {{ $t('launchService.freeTrialFeedback') }}
            </template>
        </ConfirmationModal>
    </v-container>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import i18n from 'plugins/i18n'
    import to from 'await-to-js'
    import TopologySelection from 'components/launchService/TopologySelection.vue'
    import CloudProviderSelection from 'components/launchService/CloudProviderSelection.vue'
    import ConfigurationSelection from 'components/launchService/ConfigurationSelection.vue'
    import ServerSelection from 'components/launchService/ServerSelection.vue'
    import OptionsSelection from 'components/launchService/OptionsSelection.vue'
    import SelectedServiceOverview from 'components/launchService/SelectedServiceOverview.vue'
    import SecuritySelection from 'components/launchService/SecuritySelection.vue'
    import SecuritySelectionV2 from 'components/launchService/SecuritySelectionV2.vue'
    import { getFlagIconForRegion } from 'utils/countries'
    import { createService } from 'services/dbServices'
    import { getTopologyOptions } from 'services/offerings'
    import { mapToAutoScalePolicyObjects } from 'mappers/autoScaling'
    import {
        isStandAloneTopology,
        isPrimaryReplica,
        isServerlessTopology,
        getFilteredInstanceSizes,
        isProviderAWS,
        isProviderAzure
    } from 'utils/service'
    import {
        getMultiplierFactor,
        getMaxScaleRedundancyLabel,
        getFilteredInstanceCosts,
        getLegalEntityIdentifier,
        getPricingForEndpoint,
        isPaymentSetupPendingForUser
    } from 'utils/billing'
    import { isPowerTier } from 'utils/organization'
    import PaymentModal from 'components/billing/paymentManagement/Payment.vue'
    import ConfirmationModal from 'components/common/ConfirmationModal.vue'

    export default {
        name: 'LaunchService',
        components: {
            TopologySelection,
            CloudProviderSelection,
            ConfigurationSelection,
            ServerSelection,
            OptionsSelection,
            SelectedServiceOverview,
            SecuritySelection,
            SecuritySelectionV2,
            PaymentModal,
            ConfirmationModal,
        },
        data() {
            return {
                isTopologySelected: false,
                isTransactionType: false,
                typeOfService: '',
                topologyType: '',
                topologyIcon: '',
                providerType: 'aws',
                location: null,
                locationLabel: null,
                currentLocationIcon: null,
                // this is std veutify model for keeping track
                // of opened accordion panel states
                // see https://vuetifyjs.com/en/components/expansion-panels/#model
                panel: [0, 1, 2, 3, 4, 5],
                cloudLocationHash: {},
                currentInstanceRowSelected: null,
                isServiceNameError: false,
                serviceName: '',
                selectedInstanceDisk: 100,
                selectedServer: null,
                server: null,
                serviceObject: {},
                replicas: 1,
                isReplicaDisabled: true,
                isSSlEnabled: true,
                iopsVolumeType: null,
                iopsVolumeSize: 0,
                showConfigPanel: false,
                topologyName: '',
                additionalOptions: [],
                iopsRange: [],
                selectedInstance: null,
                nodeAutoScaleOptions: [],
                storageAutoScaleOptions: [],
                isNodeAutoScaleEnabled: false,
                isTxnStorageAutoScaleEnabled: false,
                isServiceCreationInProgress: false,
                whiteListOption: null,
                isMaxScaleRedundancyEnabled: false,
                selectedMaxScaleNode: 2,
                selectedMaxScaleSize: {},
                selectedMaintenanceWindow: '',
                filteredInstances: [],
                filteredInstanceCosts: [],
                filteredMaxscaleInstances: [],
                singleZone: '',
                isSecondaryEndpointOpted: false,
                secondaryEndpointIP: null,
                selectedGp3Iops: 0,
                selectedGp3Throughput: 0,
                showPaymentDialog: false,
                overviewOffset: 20,
            }
        },
        computed: {
            ...mapGetters([
                'user',
                'services',
                'instanceSizes',
                'serverVersions',
                'currentProjectId',
                'currentTier',
                'storageSizes',
                'nodeSizes',
                'iopsValues',
                'instanceCosts',
                'storageCosts',
                'iopsCosts',
                'maintenanceWindowList',
                'maxScaleInstanceSizes',
                'availabilityZones',
                'volumeTypes',
                'gp3IopsCosts',
                'gp3StorageCosts',
                'throughputCosts',
                'endpointPricing',
                'endpointPricingError',
                'billingRecord',
                'paymentProfile',
                'isFreeTrialActive',
                'freeCreditLeft',
                'freeSCUHoursLeft',
                'storageMetering',
                'usageMetering'
            ]),
            localePathForLaunchService() {
                return 'launchService'
            },
            volumeStorageCosts() {
                if (!this.storageCosts) {
                    return {}
                }
                const volumeCosts = this.storageCosts.find(sc => sc.name === this.iopsVolumeType)
                return volumeCosts || this.storageCosts[0]
            },
            serverlessCosting() {
                let costs = []
                if (this.usageMetering) {
                    costs.push({
                        name: `${i18n.t('launchService.scuCost')}`,
                        value: i18n.t('currency', [i18n.t(
                            'perMonth',
                            { month: parseFloat(this.usageMetering.monthlyCommit).toFixed(2), }
                        )]),
                    })
                    costs.push({
                        name: i18n.t('launchService.includedScuHours'),
                        value: parseInt(this.usageMetering.startRange),
                        showToolTip: true,
                        tooltipMessage: i18n.t(
                            'launchService.includedScuHoursInfo',
                            [i18n.t('currency', [parseFloat(this.usageMetering.pricePerMonth).toFixed(2)])]
                        ),
                    })
                }
                if (this.storageMetering) {
                    costs.push({
                        name: `${i18n.t('launchService.gbStorageCost', [this.selectedInstanceDisk])}`,
                        value:
                            i18n.t(
                                'currency',
                                [i18n.t('perMonth', { month: parseFloat(
                                    this.selectedInstanceDisk * this.storageMetering.pricePerMonth
                                ).toFixed(2), })]
                            ),
                    })
                }
                return costs
            },
            isServerlessAvailable() {
                return this.$unleash.isFeatureEnabled('enable-serverless')
            },
            isServerless() {
                return isServerlessTopology(this.topologyName)
            },
            unitCosts() {
                if (this.isServerless) {
                    return this.serverlessCosting
                }

                if (!this.selectedInstance || !this.volumeStorageCosts) {
                    return []
                }
                const storagePricePerHour = this.isGP3Selected ? this.gp3StorageCosts.pricePerHour : this.volumeStorageCosts.pricePerHour
                let costs = [
                    {
                        name: this.selectedInstance.display_name,
                        value:
                            parseFloat(this.selectedInstance.pricePerHour) * this.costMultiplier,
                    },
                    {
                        name: i18n.t('launchService.ssd-storage', [this.selectedInstanceDisk]),
                        value:
                            parseFloat(storagePricePerHour) *
                            this.selectedInstanceDisk *
                            this.costMultiplier,
                    }
                ]

                // Multi node infra should not be available for SingleNode
                // and SingleNode Analytics as per 1.0
                if (!isStandAloneTopology(this.topologyName)) {
                    costs.splice(1, 0, {
                        name: i18n.t('launchService.multiNodeInfra'),
                        value:
                            parseFloat(this.selectedInstance.infraPricePerHour) *
                            this.costMultiplier,
                        showToolTip: true,
                        tooltipMessage: this.$t('launchService.scaleFabricInfo'),
                        tooltipReference: `${this.$config.DOCS_URL}skysql-dbaas/working/nr-scale-fabric/`,
                    })
                }

                if (this.isMaxScaleRedundancyEnabled && this.selectedMaxScaleSize) {
                    const { displayName, pricePerHour, } = this.selectedMaxScaleSize
                    costs.splice(1, 0, {
                        name: getMaxScaleRedundancyLabel(this.selectedMaxScaleNode, displayName),
                        value:
                            !isNaN(pricePerHour) ? parseFloat(pricePerHour) * this.selectedMaxScaleNode : 0,
                    })
                }

                if (this.isGP3Selected) {
                    if (this.selectedGp3Iops > this.gp3Iops.total_iops_range_min)
                        costs.push({
                            name: `${this.selectedGp3Iops} ${i18n.t('launchService.provisionedIOPs')}`,
                            value: parseFloat(this.gp3IopsCosts.pricePerHour) * (this.selectedGp3Iops - this.gp3Iops.total_iops_range_min) * this.costMultiplier,
                        })
                    if (this.selectedGp3Throughput > this.gp3Iops.throughput_range_min)
                        costs.push({
                            name: `${this.selectedGp3Throughput} ${i18n.t('mbPerSec')} ${i18n.t('launchService.storageThroughput')}`,
                            // TODO: throughput price check
                            value: parseFloat(this.throughputCosts.pricePerHour) * (this.selectedGp3Throughput - this.gp3Iops.throughput_range_min) * this.costMultiplier,
                        })
                }

                this.isIOPSApplicable &&
                    costs.push({
                        name: i18n.t('launchService.provisionedIOPs'),
                        value:
                            parseFloat(this.iopsCosts.pricePerHour) *
                            this.iopsVolumeSize *
                            this.selectedInstanceDisk *
                            this.costMultiplier,
                    })

                if (this.isSecondaryEndpointOpted) {
                    const endpointPricing = this.endpointPricing.find(priceObj => priceObj.provider === this.providerType)
                    endpointPricing && costs.push({
                        name: i18n.t('dualEndpoints.secondaryEndpoint'),
                        value: getPricingForEndpoint(endpointPricing.prices, 'hour'),
                    })
                }

                return costs
            },
            serviceCostEstimate() {
                if (this.isServerless) {
                    return {
                        costPerMonth:
                            this.usageMetering.monthlyCommit +
                            (this.selectedInstanceDisk * this.storageMetering.pricePerMonth),
                    }
                }

                if (!this.selectedInstance || !this.volumeStorageCosts) {
                    return null
                }

                let storagePricePerHour = this.volumeStorageCosts.pricePerHour
                if (this.isGP3Selected) storagePricePerHour = this.gp3StorageCosts.pricePerHour
                let storagePricePerMonth = this.volumeStorageCosts.pricePerMonth
                if (this.isGP3Selected) storagePricePerMonth = this.gp3StorageCosts.pricePerMonth

                let costPerHour = (parseFloat(this.selectedInstance.pricePerHour) +
                    (parseFloat(storagePricePerHour) * this.selectedInstanceDisk))

                let costPerMonth = (parseFloat(this.selectedInstance.pricePerMonth) +
                    (parseFloat(storagePricePerMonth) * this.selectedInstanceDisk))

                if (!isStandAloneTopology(this.topologyName)) {
                    costPerHour += parseFloat(this.selectedInstance.infraPricePerHour)
                    costPerMonth += parseFloat(this.selectedInstance.infraPricePerMonth)
                }

                if (this.isIOPSApplicable) {
                    costPerHour += parseFloat(this.iopsCosts.pricePerHour) * this.iopsVolumeSize * this.selectedInstanceDisk
                    costPerMonth += parseFloat(this.iopsCosts.pricePerMonth) * this.iopsVolumeSize * this.selectedInstanceDisk
                }

                if (this.isMaxScaleRedundancyEnabled && this.selectedMaxScaleSize) {
                    costPerHour += !isNaN(this.selectedMaxScaleSize.pricePerHour) ? parseFloat(this.selectedMaxScaleSize.pricePerHour) * this.selectedMaxScaleNode : 0
                    costPerMonth += !isNaN(this.selectedMaxScaleSize.pricePerMonth) ? parseFloat(this.selectedMaxScaleSize.pricePerMonth) * this.selectedMaxScaleNode : 0
                }

                if (this.isGP3Selected) {
                    if (this.selectedGp3Iops > this.gp3Iops.total_iops_range_min) {
                        const iopsSize = this.selectedGp3Iops - this.gp3Iops.total_iops_range_min
                        costPerHour += parseFloat(this.gp3IopsCosts.pricePerHour) * iopsSize
                        costPerMonth += parseFloat(this.gp3IopsCosts.pricePerMonth) * iopsSize
                    }
                    if (this.selectedGp3Throughput > this.gp3Iops.throughput_range_min) {
                        const throughputSize = this.selectedGp3Throughput - this.gp3Iops.throughput_range_min
                        costPerHour += parseFloat(this.throughputCosts.pricePerHour) * throughputSize
                        costPerMonth += parseFloat(this.throughputCosts.pricePerMonth) * throughputSize
                    }
                }

                costPerHour *= this.costMultiplier
                costPerMonth *= this.costMultiplier

                const endpointPricing = this.endpointPricing.find(priceObj => priceObj.provider === this.providerType)

                if (this.isSecondaryEndpointOpted && endpointPricing) {
                    // The secondary endpoint is not dependent on Node
                    // so the costMultiplier do not apply here
                    costPerHour += getPricingForEndpoint(endpointPricing.prices, 'hour')
                    costPerMonth += getPricingForEndpoint(endpointPricing.prices, 'month')
                }

                return {
                    costPerHour,
                    costPerMonth,
                }
            },
            costMultiplier() {
                return getMultiplierFactor(this.topologyName, this.replicas)
            },
            isIOPSApplicable() {
                const { iops: { io1, }, } = this.$config
                return this.isAWSProvider && this.iopsVolumeType === io1
            },
            isAWSProvider() {
                return isProviderAWS(this.providerType)
            },
            isAzureProvider() {
                return isProviderAzure(this.providerType)
            },
            shouldDisableCloudProvider() {
                return !this.isTopologySelected
            },
            shouldDisableInstanceSelection() {
                return !this.location
            },
            shouldDisableServiceAttribute() {
                return !this.selectedInstance
            },
            shouldDisableOtherAttributes() {
                return !this.serviceName
            },
            shouldHaveReplicaWidget() {
                return !isStandAloneTopology(this.topologyName)
            },
            topologyNodeRange() {
                if (isPrimaryReplica(this.topologyName)) {
                    return this.nodeSizes.map(node => node - 1)
                }
                return this.nodeSizes
            },
            shouldDisableSecurity() {
                return !this.serviceName
            },
            isPowerTierUser() {
                return isPowerTier(this.currentTier)
            },
            isGP3Selected() {
                return this.iopsVolumeType == this.$config.iops.gp3 && this.isAWSProvider
            },
            gp3Iops() {
                let type = this.volumeTypes.find(type => type.name == this.$config.iops.gp3)
                return type ? type.iops[0] : {}
            },
            legalEntityIdentifier() {
                return getLegalEntityIdentifier(this.billingRecord)
            },
            isFreeTrialAvailable() {
                if (this.isFreeTrialActive && !this.services.length) {
                    return true
                } else {
                    return false
                }
            },
            isAlreadyHavingServerlessService() {
                return this.services?.some(service => isServerlessTopology(service.topology))
            },
            shouldDisableLaunch() {
                return this.isServiceNameError || this.isMaxServerlessRuleViolated
            },
            isMaxServerlessRuleViolated() {
                return (
                    this.isServerless
                    && this.isAlreadyHavingServerlessService
                    && !this.user?.isEmployee
                )
            },
            shouldRequestFreeTrial() {
                return this.isFreeTrialActive && !this.freeCreditLeft && !this.isServerless
            },
            shouldPromptForPayment() {
                if (this.isFreeTrialAvailable || this.isServerless) {
                    return false
                }
                const { billingRecord, paymentProfile, } = this
                return isPaymentSetupPendingForUser({ billingRecord, paymentProfile, })
            },
            canToggleSSL() {
                return !this.isServerless
            },
            canChangeServerVersion() {
                return !this.isServerless
            },
            canConfigure() {
                return !this.isServerless ||
                    (this.$unleash.isFeatureEnabled('enable-serverless-storage-scaling') && !this.isFreeTrialActive)
            },
            canUsePSC() {
                return !this.isServerless
            },
            canSetSecurity() {
                if (this.isServerless && !this.$unleash.isFeatureEnabled('enable-serverless-allowlist')) {
                    return false
                }
                return true
            },
            canConfigureForServerless() {
                return this.isServerless &&
                    !this.isFreeTrialActive &&
                    this.$unleash.isFeatureEnabled('enable-serverless-storage-scaling')
            },
        },
        methods: {
            ...mapActions([
                'getInstanceSizes',
                'getServerVersions',
                'fetchServices',
                'fetchServiceTypes',
                'fetchProjects',
                'fetchStorageSizes',
                'fetchNodeSizes',
                'fetchIops',
                'fetchInstancePrices',
                'fetchInstancesCost',
                'fetchStorageCost',
                'fetchIOPSCost',
                'createAutoScalePolicies',
                'fetchVerticalScaleNodeSizes',
                'getMaxScaleInstanceSizes',
                'fetchMaintenanceWindowList',
                'fetchAvailabilityZones',
                'fetchVolumeTypes',
                'fetchThroughputCost',
                'fetchEndpointPricing',
                'fetchBillingRecord',
                'fetchPaymentMethods',
                'fetchCountries',
                'togglePaymentDialog',
                'fetchPrices'
            ]),
            onRequirementOfDBSelect(dbDetails) {
                this.resetOptions()
                this.isTransactionType = dbDetails.name === this.$config.TRANSACTIONAL
                this.typeOfService = dbDetails.name
            },
            async onTopologySelect(topology) {
                const currentSelectedTopology = this.topologyName
                this.isTxnStorageAutoScaleEnabled = false
                this.storageAutoScaleOptions = []
                this.resetOptions()
                this.showConfigPanel = false
                const { name, icon, } = topology
                this.topologyName = name
                this.topologyType = name
                this.topologyIcon = icon

                if (currentSelectedTopology !== this.topologyName && this.isServerless) {
                    this.providerType = this.$config.cloudProvider.AZURE
                }

                this.isReplicaDisabled = topology.display_name.toLowerCase().includes('single')
                await this.checkAdditionalOptions(topology.name)

                if (this.$unleash.isFeatureEnabled('enable-portal-auto-tune')) {
                    const autoTuneOptionName = 'auto-tune'
                    this.isTransactionType ? this.addAdditionalOption({
                        name: autoTuneOptionName,
                        label: 'enableAutoTuning',
                        hint: 'autoTuneDescription',
                    }) : this.removeAdditionalOption(autoTuneOptionName)
                }

                this.isTopologySelected = true
                this.fetchStorageSizes(name)
                this.setNodeSizesForTopology(name)
                this.fetchVolumeTypes(this.providerType)
                this.getServerVersions(this.topologyName)
                if (this.$unleash.isFeatureEnabled('enable-portal-single-zone') && this.$lodash.isEmpty(this.availabilityZones)) {
                    this.fetchAvailabilityZones()
                }
            },
            onCloudProviderSelect(type) {
                this.resetOptions()
                this.providerType = type

                if (type === this.$config.cloudProvider.AWS) {
                    this.changeIopsVolumeType(this.iopsVolumeType)
                } else {
                    this.deleteFromServiceObject('volume_iops')
                    this.deleteFromServiceObject('volume_type')
                }
                this.fetchVolumeTypes(type)
            },
            async onRegionSelect(region) {
                this.resetOptions()
                this.showConfigPanel = false
                this.location = region.name
                this.locationLabel = region.label
                this.currentLocationIcon = `$verify.icons.${getFlagIconForRegion(region.name)}`

                await Promise.all([
                    this.$lodash.isEmpty(this.instanceSizes) && this.getInstanceSizes(),
                    this.fetchInstancesCost({
                        provider: this.providerType,
                        region: region.name,
                    }),
                    this.fetchStorageCost({
                        topology: this.topologyName,
                        provider: this.providerType,
                        region: region.name,
                    }),
                    this.fetchEndpointPricing({
                        provider: this.providerType,
                        region: region.name,
                    }),
                    this.isAWSProvider && this.$lodash.isEmpty(this.iopsCosts) &&
                        this.fetchIOPSCost({
                            topology: this.topologyName,
                            provider: this.providerType,
                            region: region.name,
                        }),
                    this.isAWSProvider && this.$lodash.isEmpty(this.throughputCosts) &&
                        this.fetchThroughputCost({
                            topology: this.topologyName,
                            provider: this.providerType,
                            region: region.name,
                        }),
                    this.isAWSProvider && this.$lodash.isEmpty(this.iopsValues) && this.fetchIops(),
                    this.isPowerTierUser && this.$lodash.isEmpty(this.maxScaleInstanceSizes) && this.fetchMaxScaleSizes(),
                    this.canConfigureForServerless && this.fetchPrices({
                        offering: 'serverless',
                        params: {
                            topology: this.topologyName,
                            provider: this.providerType,
                            region: region.name,
                        },
                    })
                ])

                this.filterInstances()
                this.filterInstanceCosts()
                this.filterMaxScaleInstances()

                if (this.isAWSProvider) {
                    this.setDefaultIops()
                }

                this.showConfigPanel = true
            },
            async onInstanceConfigSelect(instance) {
                this.selectedInstance = instance

                const { TRANSACTIONAL, ANALYTICAL, } = this.$config

                await Promise.all([
                    this.fetchVerticalScaleNodeSizes({
                        // eslint-disable-next-line camelcase
                        service_type: this.isTransactionType ? TRANSACTIONAL : ANALYTICAL,
                        provider: this.providerType,
                        ...(this.isAWSProvider && { architecture: instance.architecture, }),
                        // eslint-disable-next-line camelcase
                        min_node_size: this.selectedInstance.name,
                    }),
                    this.$lodash.isEmpty(this.maintenanceWindowList) && this.fetchMaintenanceWindowList()
                ])
            },
            onStorageSelect(size) {
                this.selectedInstanceDisk = size
            },
            onReplicaChange(count) {
                this.replicas = isPrimaryReplica(this.topologyName) ? ++count : count
            },
            onMariaDBServerSelect(server) {
                if (!server) return
                this.server = server
                this.selectedServer = server.display_name
            },
            onServiceNameEntered(serviceNameObj) {
                this.isServiceNameError = serviceNameObj.hasErrors
                this.serviceName = serviceNameObj.name
            },
            getLocaleFor(key) {
                return i18n.t(`${this.localePathForLaunchService}.${key}`)
            },
            isValidAutoScaleConfig() {
                if (
                    this.isTxnStorageAutoScaleEnabled && (
                        !this.storageAutoScaleOptions.length ||
                        this.storageAutoScaleOptions[0].value <= this.selectedInstanceDisk
                    )) {
                    return false
                } else {
                    return true
                }
            },
            async launchService() {
                if (!this.isValidAutoScaleConfig()) {
                    this.$store.commit('showMessage', { text: this.getLocaleFor('autoScaleConfigError'), type: 'error', })
                    return
                }
                this.isServiceCreationInProgress = true
                this.finalizeServiceObjectForLaunch()
                // Make a post and redirect on success
                const [error, response] = await to(createService(this.serviceObject))

                if (!error) {
                    this.fetchServices()
                    const actions = this.setupAutoScaling()
                    if (actions.length) {
                        // eslint-disable-next-line camelcase
                        let scalingPolicies = { service_name: this.isServerless ? response.id : response.name, service_id: response.id, actions, }
                        await this.createAutoScalePolicies(scalingPolicies)
                    }

                    this.$router.push({ name: 'dashboard', })
                }
                this.isServiceCreationInProgress = false
            },
            finalizeServiceObjectForLaunch() {
                this.appendToServiceObject('project_id', this.currentProjectId)
                this.appendToServiceObject('service_type', this.typeOfService)
                this.appendToServiceObject('topology', this.topologyName)
                this.appendToServiceObject('provider', this.providerType)
                this.appendToServiceObject('region', this.location)
                this.appendToServiceObject('name', this.serviceName)
                if (this.canSetSecurity && this.whiteListOption) {
                    this.setupServiceObjectForWhiteListing()
                }
                if (this.$unleash.isFeatureEnabled('enable-portal-dual-endpoints') && this.isSecondaryEndpointOpted) {
                    this.setupServiceObjectForSecondaryEndpoint()
                }
                if (this.isPowerTierUser) {
                    this.appendToServiceObject('maintenance_window', this.selectedMaintenanceWindow ? this.selectedMaintenanceWindow : this.maintenanceWindowList[0].name)
                }
                // Architecture is supported only for AWS provider
                if (!this.isServerless) {
                    this.appendToServiceObject('architecture', this.selectedInstance?.architecture || this.$config.DEFAULT_ARCHITECTURE)
                    this.appendToServiceObject('nodes', this.replicas)
                    this.appendToServiceObject('size', this.selectedInstance.name)
                }
                if (!this.isServerless || this.canConfigureForServerless) {
                    this.appendToServiceObject('storage', this.selectedInstanceDisk)
                }
                this.appendToServiceObject('ssl_enabled', this.isSSlEnabled)
                this.appendToServiceObject('version', this.server.name)
                // Don't send volume_type for GCP
                if ((this.isAWSProvider || this.isAzureProvider) && this.iopsVolumeType && !this.isServerless) {
                    this.appendToServiceObject('volume_type', this.iopsVolumeType)
                }
                if (this.iopsVolumeType === this.$config.iops.io1 && !this.isServerless) {
                    this.deleteFromServiceObject('volume_iops')
                    this.appendToServiceObject('volume_iops', this.iopsVolumeSize * this.selectedInstanceDisk)
                }
                if (this.isGP3Selected && !this.isServerless) {
                    this.appendToServiceObject('volume_iops', this.selectedGp3Iops)
                    this.appendToServiceObject('volume_throughput', this.selectedGp3Throughput)
                }
                if (this.additionalOptions.length) {
                    this.additionalOptions.forEach((option) => this.appendToServiceObject(option.name, option.value))
                }
                if (this.isMaxScaleRedundancyEnabled && this.setMaxScaleSize) {
                    this.appendToServiceObject('maxscale_nodes', this.selectedMaxScaleNode)
                    this.appendToServiceObject('maxscale_size', this.selectedMaxScaleSize.name)
                }
                if (this.$unleash.isFeatureEnabled('enable-portal-single-zone') && this.singleZone) {
                    this.appendToServiceObject('availability_zone', this.singleZone)
                }
            },
            setupServiceObjectForWhiteListing() {
                const { name, value, } = this.whiteListOption[0]
                if (name === 'ip-whitelist') {
                    if (value) this.appendToServiceObject('allow_list', [{ 'ip': value, }])
                    this.deleteFromServiceObject('endpoint_mechanism')
                    this.deleteFromServiceObject('endpoint_allowed_accounts')
                } else {
                    // private link/psc setup
                    this.appendToServiceObject('endpoint_mechanism', this.$config.ENDPOINT_MECHANISM_PRIVATELINK)
                    // Empty array as launch has no option to select project id
                    this.appendToServiceObject('endpoint_allowed_accounts', [])
                }
            },
            appendToServiceObject(key, value) {
                this.serviceObject[key] = value
            },
            deleteFromServiceObject(key) {
                delete this.serviceObject[key]
            },
            onSSLToggle(value) {
                this.isSSlEnabled = !value
            },
            changeIopsVolumeSize(size) {
                // Handle iops volume size change
                this.iopsVolumeSize = size
            },
            changeIopsVolumeType(type) {
                this.iopsVolumeType = type
            },
            async checkAdditionalOptions(topologyName) {
                this.additionalOptions = []
                const [error, response] = await to(getTopologyOptions(topologyName))
                if (error || !response) {
                    return
                }

                if (response.nosql_available) {
                    this.addAdditionalOption({
                        name: 'nosql_available',
                        type: 'checkbox',
                        value: false,
                        label: 'enableNoSQL',
                        hint: 'noSQLInfo',
                    })
                } else if (response.scale_options && this.$unleash.isFeatureEnabled('enable-serverless-scu-scaling')) {
                    this.addAdditionalOption({
                        name: 'cpu_scale_options',
                        type: 'range',
                        value: response.scale_options.cpu?.default,
                        props: {
                            min: response.scale_options.cpu?.range[0],
                            max: response.scale_options.cpu?.range[1],
                        },
                        label: 'scuScaling',
                        hint: 'scuScaleInfo',
                    })
                }
            },
            addAdditionalOption(option = {}) {
                if (this.additionalOptions.findIndex(({ name, }) => option.name === name) !== -1) {
                    return
                }
                this.additionalOptions.push(option)
            },
            removeAdditionalOption(optionName) {
                const optionIndex = this.additionalOptions.findIndex(option => option.name === optionName)
                if (optionIndex !== -1) {
                    this.additionalOptions.splice(optionIndex, 1)
                }
            },
            updateAdditionalOption({ optionName, value, }) {
                let additionalOption = this.additionalOptions.find(options => options.name === optionName)
                if (additionalOption) {
                    additionalOption.value = value
                }
            },
            async setNodeSizesForTopology(topology) {
                await this.fetchNodeSizes(topology)
                // Defaulting the nodes to second value coming
                // from API
                // In case of analytics only single value is supported
                if (this.nodeSizes && this.nodeSizes.length) {
                    const defaultReplicaSize = this.getDefaultNodeSizeForTopology(this.topologyName, this.nodeSizes)
                    this.replicas = defaultReplicaSize
                }
            },
            onAutoNodeScaleTypeSelection(scaleType) {
                // We support single scale option, so pop out existing
                if (this.nodeAutoScaleOptions.length) this.nodeAutoScaleOptions.pop()
                this.nodeAutoScaleOptions.push({ type: scaleType, })
            },
            onAutoNodeScaleValueSelection(scaleValue) {
                this.nodeAutoScaleOptions[0].maxValue = scaleValue
            },
            onAutoStorageScaleValueSelection(storageValue) {
                // We support single scale option, so pop out existing
                if (this.storageAutoScaleOptions.length) this.storageAutoScaleOptions.pop()
                this.storageAutoScaleOptions.push({
                    type: this.$config.AUTO_STORAGE_SCALE,
                    value: storageValue,
                })
            },
            onStorageAutoToggle(isChecked) {
                this.isTxnStorageAutoScaleEnabled = isChecked
                if (!isChecked) this.storageAutoScaleOptions.pop()
            },
            onNodeAutoToggle(isChecked) {
                this.isNodeAutoScaleEnabled = isChecked
                if (!isChecked) this.nodeAutoScaleOptions.pop()
            },
            setupAutoScaling() {
                // Is Node Scaling enabled
                let autoScalePolicies = []
                if (this.isNodeAutoScaleEnabled && this.nodeAutoScaleOptions.length) {
                    if (this.nodeAutoScaleOptions[0].type === this.$config.AUTO_VERTICAL_SCALE) {
                        this.nodeAutoScaleOptions[0].minValue = this.serviceObject.size
                    } else {
                        this.nodeAutoScaleOptions[0].minValue = this.serviceObject.nodes
                    }
                    autoScalePolicies.push(this.nodeAutoScaleOptions.map(mapToAutoScalePolicyObjects))
                }

                // Is storage Scaling enabled
                if (this.isTxnStorageAutoScaleEnabled && this.storageAutoScaleOptions.length) {
                    autoScalePolicies.push(this.storageAutoScaleOptions.map(mapToAutoScalePolicyObjects))
                }
                return autoScalePolicies.flat()
            },
            resetOptions() {
                this.singleZone = ''
                this.resetStorageInstanceStep()
            },
            resetTopologyType() {
                this.topologyName = ''
                this.topologyType = ''
                this.isTopologySelected = false
            },
            resetCloudProviderStep() {
                this.providerType = ''
            },
            resetRegionSelectionStep() {
                this.location = null
                this.locationLabel = ''
                this.currentLocationIcon = ''
                this.showConfigPanel = false
            },
            resetStorageInstanceStep() {
                this.selectedInstanceDisk = this.canConfigureForServerless ? 10 : 100
                if (this.topologyName && this.nodeSizes) {
                    this.replicas = this.getDefaultNodeSizeForTopology(this.topologyName, this.nodeSizes)
                }
                this.setDefaultIops()
            },
            resetServiceAttributeStep() {
                this.selectedServer = null
                this.isSSlEnabled = true
            },
            resetAdditionalOptions() {
                this.additionalOptions = []
            },
            resetSecurityOptions() {
                this.isSSlEnabled = true
                this.whiteListOption = null
            },
            // Get default node size for topology
            // to initialize the launch flow
            getDefaultNodeSizeForTopology(topology, nodeSizes) {
                if (isPrimaryReplica(topology)) {
                    return 1
                }
                return nodeSizes[0] || 1
            },
            handleServiceSecurity(val) {
                this.whiteListOption = val
            },
            onMaxScaleToggle(isChecked) {
                this.isMaxScaleRedundancyEnabled = isChecked
            },
            fetchMaxScaleSizes() {
                this.getMaxScaleInstanceSizes({
                    type: this.$config.INSTANCE_SIZE_TYPE.PROXY,
                })
            },
            setMaxScaleNode(val) {
                this.selectedMaxScaleNode = val
            },
            setMaxScaleSize(val) {
                this.selectedMaxScaleSize = val
            },
            onMaintenanceWindowSelect(val) {
                this.selectedMaintenanceWindow = val
            },
            filterInstances() {
                // Filter instances for the current user selection
                // from list of all the sizes fetched from API
                this.filteredInstances = getFilteredInstanceSizes(this.instanceSizes, {
                    provider: this.providerType,
                    serviceType: this.typeOfService,
                    topology: this.topologyName,
                })
            },
            filterInstanceCosts() {
                if (!this.filteredInstances[0]) {
                    return []
                }
                const { provider, topology, } = this.filteredInstances[0]
                this.filteredInstanceCosts = getFilteredInstanceCosts(this.instanceCosts, {
                    provider,
                    topology,
                })
            },
            filterMaxScaleInstances() {
                this.filteredMaxscaleInstances = getFilteredInstanceSizes(this.maxScaleInstanceSizes, {
                    provider: this.providerType,
                    serviceType: this.typeOfService,
                    topology: this.topologyName,
                })
            },
            setDefaultIops() {
                this.changeIopsVolumeSize(this.$typy(this, 'iopsValues[0].default_iops_per_gb').safeNumber || this.$config.IOPS_DEFAULT)
            },
            onZoneSelection(zone) {
                this.singleZone = zone
            },
            handleSecondaryEndpointAction(isSecondaryEndpointOpted) {
                this.isSecondaryEndpointOpted = isSecondaryEndpointOpted
            },
            handleSecondaryEndpointIpSelect(selectedIP) {
                this.secondaryEndpointIP = selectedIP
            },
            setupServiceObjectForSecondaryEndpoint() {
                // Backend limitation to have max 16 length
                // and regex to match ^[a-z][a-z0-9-]+$
                // so generating a random string using only
                // lowercase alphabets
                const name = this.$help.getUniqueString(16)
                this.appendToServiceObject('endpoints', [{ name, }])
                // In case a Secondary endpoint is selected with current IP
                // set the allow_list considering the IP selected
                // as it is shared IP
                // Overriding the existing allow_list will not change anything
                if (this.secondaryEndpointIP) {
                    this.appendToServiceObject('allow_list', [{ 'ip': this.secondaryEndpointIP, }])
                }
            },
            onIopsChange(val) {
                this.selectedGp3Iops = val
            },
            onThroughputChange(val) {
                this.selectedGp3Throughput = val
            },
            async openPaymentDialog() {
                if (this.$lodash.isEmpty(this.paymentProfile)) {
                    await this.fetchPaymentMethods()
                }
                this.showPaymentDialog = true
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    // Minor delay is required to open the
                    // payment dialog for cached data
                    await this.$nextTick()
                }
                this.togglePaymentDialog(true)
            },
            handleProfileUpdate() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
                // On success perform the launch action
                this.launchService()
            },
            handleProfileClose() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
            },
            setDefaultServiceName() {
                const suffix = this.$help.getRandomString(4).toLowerCase()
                const defaultName = `${this.user.firstName?.toLowerCase()}-${this.user.lastName?.toLowerCase()}-${suffix}`
                const validation = /^[a-z][a-z0-9-]{0,23}$/
                if (validation.test(defaultName)) {
                    this.serviceName = defaultName
                } else {
                    this.serviceName = `my-service-${suffix}`
                }
            },
            onScroll(e) {
                let max = 99999
                if (this.$refs.options && this.$refs.summary) {
                    max = this.$refs.options.offsetHeight - this.$refs.summary.offsetHeight - 20
                }
                const scrollOffset = e.target.scrollTop < 60 ? 20 : e.target.scrollTop - 20
                this.overviewOffset = Math.min(max, scrollOffset)
            },
            tryLaunch () {
                if (this.shouldRequestFreeTrial) {
                    window.open(this.$config.CONTACT_URL, '_blank')
                } else if (this.isFreeTrialAvailable && this.$refs.confirmFreeTrialModal) {
                    this.$refs.confirmFreeTrialModal.open()
                } else if (this.shouldPromptForPayment) {
                    this.openPaymentDialog()
                } else {
                    this.launchService()
                }
            },
        },
        created() {
            if (this.$lodash.isEmpty(this.currentProjectId)) this.fetchProjects()
            this.fetchBillingRecord()
            this.fetchServices()
            if (this.$lodash.isEmpty(this.paymentProfile)) this.fetchPaymentMethods()
            this.setDefaultServiceName()
        },
        mounted() {
            const mainWrapEl = document.getElementsByClassName('v-main__wrap')
            if (mainWrapEl[0]) {
                mainWrapEl[0].addEventListener('scroll', this.onScroll)
            }
        },
        beforeDestroy() {
            const mainWrapEl = document.getElementsByClassName('v-main__wrap')
            if (mainWrapEl[0]) {
                mainWrapEl[0].removeEventListener('scroll', this.onScroll)
            }
        },
    }
</script>

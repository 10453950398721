var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.title)?_c('v-row',{staticClass:"mt-2 text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"d-flex"},[_c('v-checkbox',{staticClass:"autoscale-label",attrs:{"disabled":_vm.isServerless || _vm.shouldDisableScaling,"label":_vm.autoScaleLabel},on:{"change":_vm.onCheck},model:{value:(_vm.isStorageScaleEnabled),callback:function ($$v) {_vm.isStorageScaleEnabled=$$v},expression:"isStorageScaleEnabled"}}),_c('div',{staticClass:"mt-4 pl-2"},[_c('HelpToolTip',{scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$t('launchService.autoScaleStorageInfo'))+" ")]),_c('a',{staticClass:"text-capitalize",attrs:{"rel":"noopener noreferrer","target":"_blank","href":`${_vm.$config.DOCS_URL}Autonomously%20scale%20Compute,%20Storage/#enable-auto-scaling-of-storage`}},[_vm._v(" "+_vm._s(_vm.$t('learn-more'))+" ")])]},proxy:true}])})],1)],1),_c('v-col',{staticClass:"selection-group"},[(_vm.isStorageScaleEnabled)?_c('v-select',{attrs:{"items":_vm.autoScaleStorageOptions,"item-text":"text","item-value":"val","placeholder":_vm.$t('maximum-size'),"required":"","open-on-clear":"","rules":[
                    (val) =>
                        !!val ||
                        _vm.$t('errors.fieldRequired', {
                            field: _vm.$t('ssd-storage-gb'),
                        }),
                ],"disabled":_vm.shouldDisableScaling,"menu-props":{ top: false, offsetY: true },"outlined":""},on:{"change":_vm.onStorageSelection},model:{value:(_vm.selectedStorage),callback:function ($$v) {_vm.selectedStorage=$$v},expression:"selectedStorage"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
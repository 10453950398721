<style lang="scss" scoped>
.current-ip {
    background: $pale-yellow;
}

.not-recommended {
    color: $error
}
</style>
<template>
    <v-expansion-panel
        id="securityPanel"
        :disabled="isDisabled">
        <AccordianPanelHeader
            :title="$t('launchService.security')"
            :show-tool-tip="false" />
        <v-expansion-panel-content>
            <div>
                <p class="mt-4">
                    <span>{{ $t('dualEndpoints.securityPrimaryEndpointInfo') }}</span>
                    <span v-if="isPscSupported">: {{ $t('dualEndpoints.securityOptionsInfo', { pscType: privateConnectType }) }}</span>
                </p>
                <div>
                    <div class="text-uppercase">
                        {{ $t('dualEndpoints.primaryEndpoint') }}
                    </div>
                    <div>
                        <v-radio-group
                            v-model="whitelistOption"
                            row
                            class="pb-0 mb-0"
                            @change="onServiceWhiteListOptionSelection">
                            <v-row>
                                <v-col>
                                    <v-radio :value="$config.IP_WHITELIST">
                                        <template #label>
                                            <div>
                                                <span class="mr-2 text--primary">{{ $t('launchService.allowIP') }}</span>
                                                <HelpTooltip>
                                                    <template #message>
                                                        <div>{{ $t('launchService.whitelistInfo') }}</div>
                                                    </template>
                                                </HelpTooltip>
                                            </div>
                                        </template>
                                    </v-radio>
                                </v-col>
                                <v-col v-if="isPscSupported">
                                    <v-radio :value="$config.PSC">
                                        <template #label>
                                            <span class="mr-2 text--primary">{{ privateConnectType }} </span>
                                            <HelpTooltip>
                                                <template #message>
                                                    <p>
                                                        {{ $t('launchService.privateConnectInfo', {
                                                            connectionName: privateConnectType
                                                        }) }}
                                                    </p>
                                                    <a
                                                        rel="noopener noreferrer"
                                                        class="text-capitalize"
                                                        target="_blank"
                                                        :href="privateConnectLearnMoreLink">{{
                                                            $t('learn-more')
                                                        }}</a>
                                                </template>
                                            </HelpTooltip>
                                        </template>
                                    </v-radio>
                                </v-col>
                            </v-row>
                        </v-radio-group>
                        <v-row>
                            <v-col cols="6">
                                <div
                                    v-if="whitelistOption === $config.IP_WHITELIST"
                                    class="pt-0 mt-0 pl-5">
                                    <v-checkbox
                                        v-if="currentIp"
                                        v-model="isCurrentIpSelected"
                                        class="mt-0 pt-0"
                                        @change="useCurrentIP">
                                        <template #label>
                                            <div class="text--primary">
                                                {{ $t('dualEndpoints.addMyIP') }} {{ currentIp }}
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <span class="text--secondary">
                                        {{ $t('dualEndpoints.addApprovedIPsLater', { also : currentIp ? $t('dualEndpoints.also') : '' }) }}
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>

                <!-- Secondary Endpoint Option -->
                <div class="mt-4">
                    <div v-if="secondaryEndpointOpted">
                        <div>
                            <span class="text-uppercase">{{ $t('dualEndpoints.secondaryEndpoint') }} ({{
                                $t('dualEndpoints.optional') }})</span>
                            <v-btn
                                class="text-capitalize ml-4"
                                outlined
                                color="primary"
                                rounded
                                @click="toggleSecondaryEndpoint(false)">
                                {{ $t('remove') }}
                            </v-btn>
                        </div>
                        <div>
                            <v-col class="pl-0">
                                <v-radio-group v-model="secondaryEndpointOptions">
                                    <v-radio value="secondary-nlb">
                                        <template #label>
                                            <div>
                                                <span class="mr-2 text-capitalize text--primary">{{
                                                    $t('launchService.allowIP') }}</span>
                                                <HelpTooltip>
                                                    <template #message>
                                                        <div>{{ $t('launchService.whitelistInfo') }}</div>
                                                    </template>
                                                </HelpTooltip>
                                            </div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <div
                                    v-if="whitelistOption !== $config.IP_WHITELIST"
                                    class="pt-0 mt-0 pl-5">
                                    <v-checkbox
                                        v-if="currentIp"
                                        v-model="secondaryIP"
                                        class="mt-0 pt-0"
                                        @change="useCurrentIPForSecondaryEndpoint">
                                        <template #label>
                                            <div class="text--primary">
                                                {{ $t('dualEndpoints.addMyIP') }} {{ currentIp }}
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <div class="text--secondary">
                                        {{ $t('dualEndpoints.addApprovedIPsLater', { also : currentIp ? $t('dualEndpoints.also') : '' }) }}
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="text--secondary">
                                    {{ $t('dualEndpoints.sharedAllowListInfo') }}
                                </div>
                            </v-col>
                        </div>
                    </div>
                    <div v-else-if="isPscSupported">
                        <v-btn
                            class="text-capitalize"
                            outlined
                            color="primary"
                            rounded
                            @click="toggleSecondaryEndpoint(true)">
                            {{ $t('add') }} {{ $t('dualEndpoints.secondaryEndpoint') }}
                        </v-btn>
                    </div>
                </div>

                <v-divider
                    v-if="showSsl"
                    class="mt-6 mb-6" />
                <!-- SSL Option -->
                <v-row
                    v-if="showSsl"
                    class="mt-1">
                    <v-checkbox
                        v-model="isSSLDisabled"
                        class="ml-3 pt-0 mt-0"
                        @change="onChangeSSLToggle">
                        <template #label>
                            <div>
                                <span class="mr-3">{{ $t('launchService.disable-ssl') }}</span>
                                <HelpTooltip>
                                    <template #message>
                                        <div>{{ $t('launchService.disableSSLInfo') }}</div>
                                    </template>
                                </HelpTooltip>
                                <span class="not-recommended pl-2">
                                    {{ $t('launchService.notRecommended') }}
                                </span>
                            </div>
                        </template>
                    </v-checkbox>
                </v-row>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import to from 'await-to-js'
    import { getPrivateConnectInstructionLink, privateConnectLabel } from 'utils/service'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    import HelpTooltip from 'components/common/HelpTooltip.vue'
    import { getCurrentIP } from 'services/misc'
    export default {
        name: '',
        components: { AccordianPanelHeader, HelpTooltip, },
        props: {
            isSSlEnabled: Boolean,
            isDisabled: {
                type: Boolean,
                default: true,
            },
            showSsl: {
                type: Boolean,
                default: true,
            },
            providerType: {
                type: String,
                required: true,
            },
            isPscSupported: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                whitelistOption: this.$config.IP_WHITELIST,
                isSSLDisabled: !this.isSSlEnabled,
                currentIp: null,
                isCurrentIpSelected: false,
                secondaryEndpointOpted: false,
                secondaryEndpointOptions: 'secondary-nlb',
                secondaryIP: null,
            }
        },
        computed: {
            privateConnectLearnMoreLink() {
                return getPrivateConnectInstructionLink(this.providerType)
            },
            privateConnectType() {
                return privateConnectLabel(this.providerType, true)
            },
        },
        watch: {
            isSSlEnabled(val) {
                this.isSSLDisabled = !val
            },
        },
        methods: {
            onChangeSSLToggle(value) {
                this.$emit('onSSLToggle', value)
            },
            onServiceWhiteListOptionSelection() {
                let whitelistOptions = []
                // Always reset secondary IP when
                // switch happens between primary EP
                this.secondaryIP = false
                if (this.whitelistOption === this.$config.PSC) {
                    // Restore uncheck state of whitelist option
                    // when a user selects PSC type
                    this.isCurrentIpSelected = false
                    whitelistOptions.push({ name: this.whitelistOption, })
                } else {
                    whitelistOptions.push({ name: this.whitelistOption, value: this.isCurrentIpSelected ? this.getAPISupportedIP() : null, })
                }
                this.$emit('on-service-security-select', whitelistOptions)
            },
            useCurrentIP() {
                this.$emit('on-service-security-select', [{ name: this.whitelistOption, value: this.isCurrentIpSelected ? this.getAPISupportedIP() : null, }])
            },
            getAPISupportedIP() {
                // appending /32 to support as per API needs
                // to properly whitelist single IP rather than range
                return `${this.currentIp}/32`
            },
            toggleSecondaryEndpoint(toggle) {
                this.secondaryEndpointOpted = toggle
                this.$emit('on-secondary-endpoint-toggled', toggle)
            },
            useCurrentIPForSecondaryEndpoint() {
                this.$emit('on-secondary-endpoint-current-ip-select', this.secondaryIP ? this.getAPISupportedIP() : null)
            },
        },
        async created() {
            const [error, response] = await to(getCurrentIP())
            if (!error) {
                this.currentIp = response.data.trim()
                // Preselect
                this.isCurrentIpSelected = true
                this.useCurrentIP()
            }
        },
    }
</script>

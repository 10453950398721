<template>
    <v-container fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <PaymentTable
                :payment-methods="hasPaymentProfile ? paymentProfile?.filter(profile => profile?.paymentMethodId) : []"
                :loading="isFetchingInvoiceDeps"
                @add-payment="openPaymentDialog"
                @edit-payment="handlePaymentUpdate"
                @delete-payment="handlePaymentDelete" />
        </v-col>
        <div v-if="showPaymentDialog">
            <Payment
                :legal-entity-identifier="legalEntityIdentifier"
                :is-for-edit="isForEdit"
                :payment-method-for-edit="paymentMethodForEdit"
                @on-update-profile="handleProfileUpdate"
                @on-payment-close="handleProfileClose" />
        </div>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Payment from 'components/billing/paymentManagement/Payment.vue'
    import PaymentTable from 'components/billing/paymentManagement/PaymentTable.vue'

    export default {
        name: 'ContactManagement',
        components: { Payment, PaymentTable, },
        data() {
            return {
                isFetchingInvoiceDeps: true,
                showPaymentDialog: false,
                isForEdit: false,
                paymentMethodForEdit: null,
            }
        },
        computed: {
            ...mapGetters([
                'billingRecord',
                'paymentProfile',
                'isFetchingBillingRecord',
                'isFetchingPaymentDetail',
                'billingCountries'
            ]),
            hasPaymentProfile() {
                return this.paymentProfile?.length
            },
            isRemittance() {
                if (this.billingRecord) return this.billingRecord?.remittance
                return false
            },
            legalEntityIdentifier() {
                if (!this.billingRecord) return ''
                return this.billingRecord?.legalEntityIdentifier || ''
            },
        },
        methods: {
            ...mapActions([
                'togglePaymentDialog',
                'fetchBillingRecord',
                'fetchPaymentMethods',
                'fetchCountries'
            ]),
            async handlePaymentDelete() {
                await this.fetchPaymentMethods()
            },
            async handlePaymentUpdate(paymentMethod) {
                this.isForEdit = true
                this.paymentMethodForEdit = paymentMethod
                this.openPaymentDialog()
            },
            async openPaymentDialog() {
                if (this.$lodash.isEmpty(this.paymentProfile)) {
                    await this.fetchPaymentMethods()
                }
                this.showPaymentDialog = true
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    // Minor delay is required to open the
                    // payment dialog for cached data
                    await this.$nextTick()
                }
                this.togglePaymentDialog(true)
            },
            async handleProfileUpdate() {
                this.fetchBillingRecord()
                await this.fetchPaymentMethods()
                this.handleProfileClose()
            },
            handleProfileClose() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
                this.isForEdit = false
                this.paymentMethodForEdit = null
            },
        },
        async mounted() {
            if (this.$lodash.isEmpty(this.billingRecord)) {
                this.fetchBillingRecord()
            }
            if (this.$lodash.isEmpty(this.paymentProfile)) {
                await this.fetchPaymentMethods()
            }
            this.isFetchingInvoiceDeps = false
        },
    }
</script>

<style lang="scss" scoped>
.replicas-container {
    position: relative;
    background-color: #edf5fa;
    margin: 0px -24px -16px -24px;

    v-col {
        margin-top: 20px;
    }

    .coming-soon {
        position: absolute;
        top: 0;
        right: 0;
        background: $selection-highlight;
        border-radius: 0px 0px 0px 10px;
        padding: 4px;
        font-weight: 600;
        font-size: 12px;
        color: $background
    }

    .triangle-down-shape {
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-top: 12px solid #ffffff;
        margin-left: 4.5rem;
        position: absolute;
    }

    .replica-text-wrapper {
        margin-top: 1rem;

        .replica-title {
            font-size: 1rem;
        }

        .replica-text {
            font-size: 0.9rem;
            padding: 0px;
        }
    }
}

.storage-footnote {
    font-size: 14px;
}

.power-offering {
    font-weight: 600;
}

.storage-note {
    font-size: 10px;
}
</style>
<template>
    <v-expansion-panel
        id="configurationPanel"
        :disabled="isDisabled">
        <AccordianPanelHeader :title="widgetTitle">
            <template #tooltip-content>
                <div v-if="isServerless">
                    {{ $t('launchService.storageInfo') }}
                </div>
                <div v-else-if="isNodes">
                    {{ $t('launchService.instanceStorageInfo') }}
                </div>
                <div v-else>
                    {{ $t('launchService.instanceStorageReplicaInfo') }}
                </div>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content>
            <div v-if="showConfigPanel">
                <template v-if="isServerless">
                    <v-row class="mt-4 mb-4">
                        <v-col cols="12">
                            <div class="d-flex align-center justify-space-between">
                                <span class="text-body-2 font-weight-medium">
                                    {{ $t('launchService.storage-size') }} <a href="#">{{ storageSize }}</a> GB
                                </span>
                                <span
                                    v-if="storageMetering?.pricePerHour"
                                    class="text-body-2">
                                    {{
                                        $t('launchService.storageCost')
                                    }}: {{
                                        $t('currency',[$t('perGBMonth', { month: parseFloat(storageMetering?.pricePerMonth).toFixed(5)} )])
                                    }}
                                </span>
                            </div>
                        </v-col>

                        <v-col
                            v-if="canServerlessManualScale && storageRange.length"
                            cols="12">
                            <Slider
                                :min-value="1"
                                :max-value="storageRange.length"
                                :step-value="1"
                                class-name="storage-selector"
                                :ticks-labels="storageRange"
                                ticks="false"
                                @handleChange="handleStorageSelection" />
                        </v-col>
                    </v-row>

                    <StorageAutoScale
                        class="mb-2"
                        :storage-range="availableStorageSizeRange"
                        :should-disable="isMaxStorageSelected"
                        :is-g-p3-selected="false"
                        :is-serverless="true"
                        :default-storage-value="500"
                        @on-toggle-auto-scaling="onAutoStorageToggle"
                        @on-auto-storage-value="onAutoStorageScaleSelected" />
                </template>
                <template v-else>
                    <p class="mt-4 text-body-2">
                        {{ $t('launchService.select-instance') }}
                    </p>
                    <ServerInstanceList
                        v-if="!isInstanceSizeFetching && instanceData.length"
                        :instance-list="instanceData"
                        :instance-cost="instanceCost"
                        :pre-selected-instance-id="instanceData[0].id"
                        :is-a-w-s="isAWSProvider"
                        @instanceSelected="handleInstanceConfigSelection" />

                    <InstanceTierUpgradeBanner v-if="isFoundationTier" />

                    <NodeAutoScale
                        :can-scale-horizontal="canScaleHorizontal"
                        :horizontal-scale-range="availableHorizontalScaleRange"
                        :vertical-scale-range="autoVerticalSizes"
                        @on-toggle-auto-scaling="onAutoNodeToggle"
                        @on-auto-scale-type="onAutoScaleTypeForNodeSelected"
                        @on-auto-scale-value="onAutoScaleValueForNodeSelected" />

                    <VolumeTypeSelection
                        v-if="volumeTypes.length > 1"
                        :volume-types="volumeTypes"
                        @handleIopsTypeChange="handleIopsTypeChange" />

                    <v-row
                        v-if="storageRange.length"
                        class="mt-4 mb-n12">
                        <v-col
                            cols="8"
                            class="pb-0">
                            <span class="text-body-2 font-weight-medium">{{ $t('launchService.txn-storage') }} <a href="#">{{ storageSize }}</a> {{
                                $t('launchService.per-replica', {
                                    type: (isNodes ? $tc('launchService.nodes', 1) :
                                        $tc('launchService.replicas', 1))
                                }) }}</span>
                            <Slider
                                :min-value="1"
                                :max-value="storageRange.length"
                                :step-value="1"
                                class-name="storage-selector"
                                :ticks-labels="storageRange"
                                ticks="false"
                                @handleChange="handleStorageSelection" />
                        </v-col>
                        <v-col cols="4">
                            <p class="storage-note pt-6">
                                <span v-if="isAWSProvider">
                                    {{ $t('launchService.awsSSD') }}
                                </span>
                                <span v-else-if="isGCPProvider">
                                    {{ $t('launchService.gcp-ssd') }}
                                </span>
                                <span v-else>
                                    {{ $t('launchService.azure-ssd') }}
                                </span>
                            </p>
                        </v-col>
                        <v-col />
                    </v-row>

                    <v-row
                        v-if="isAWSProvider && iopsValues.length"
                        class="mb-n6 mt-n6">
                        <v-col cols="12">
                            <IopsSelector
                                :current-value="defaultIopsVolumeSize"
                                :min-value="iopsMinValue"
                                :max-value="iopsMaxValue"
                                :step-value="1"
                                :default-iops-type="defaultIopsVolumeType"
                                :default-iops-size="defaultIopsVolumeSize"
                                :storage-size="storageSize"
                                @handleIopsVolumeChange="handleIopsVolumeChange" />
                        </v-col>
                    </v-row>

                    <IopsAndThroughputSelection
                        v-if="isGP3Selected"
                        @on-iops-change="onIopsChange"
                        @on-throughput-change="onThroughputChange" />

                    <StorageAutoScale
                        class="mt-n12"
                        :class="{ 'pb-4': !showMaxScale }"
                        :storage-range="availableStorageSizeRange"
                        :should-disable="isMaxStorageSelected"
                        :is-g-p3-selected="isGP3Selected"
                        @on-toggle-auto-scaling="onAutoStorageToggle"
                        @on-auto-storage-value="onAutoStorageScaleSelected" />

                    <MaxScaleSelection
                        v-if="showMaxScale"
                        class="mt-n12 pb-4"
                        :max-scale-instances="maxScaleInstances"
                        :selected-instance="selectedInstance"
                        :instance-cost="instanceCost"
                        @on-maxscale-toggle="onMaxScaleToggle"
                        @on-maxscale-node-selection="setMaxScaleNode"
                        @on-maxscale-size-selection="setMaxScaleSize" />

                    <v-row
                        v-if="showReplicaWidget"
                        class="replicas-container">
                        <div
                            v-if="checkIfColumnStore"
                            class="coming-soon text-uppercase">
                            {{ $t('comingSoon') }}
                        </div>
                        <span class="triangle-down-shape"></span>
                        <v-col cols="3">
                            <ReplicaSelector
                                :count="currentNodes"
                                :is-disabled="isReplicaDisabled"
                                :range="topologyRange"
                                @handleReplicaChange="handleReplicaChange" />
                        </v-col>
                        <v-col
                            cols="9"
                            class="replica-text-wrapper">
                            <p class="replica-title mb-0">
                                {{ isNodes ? $t('launchService.nodes-need', { clusterName: getClusterName }) :
                                    $t('launchService.replica-need') }}
                            </p>
                            <p class="replica-text">
                                {{ $t('launchService.replica-combination', {
                                    type: (isNodes ? $tc('launchService.nodes', 1)
                                        : $tc('launchService.replicas', 1))
                                }) }}
                            </p>
                        </v-col>
                    </v-row>
                </template>
            </div>
            <v-progress-circular
                v-else
                size="40"
                width="7"
                color="primary"
                indeterminate />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { mapGetters } from 'vuex'
    import Slider from 'components/Slider.vue'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    import ReplicaSelector from 'components/launchService/ReplicaSelector.vue'
    import IopsSelector from 'components/launchService/IopsSelector.vue'
    import ServerInstanceList from 'components/common/ServerInstanceList.vue'
    import InstanceTierUpgradeBanner from 'components/common/InstanceTierUpgradeBanner.vue'
    import StorageAutoScale from 'components/autonomous/StorageAutoScale.vue'
    import NodeAutoScale from 'components/autonomous/NodeAutoScale.vue'
    import iopsComputation from 'mixins/iopsComputation.js'
    import VolumeTypeSelection from 'components/launchService/VolumeTypeSelection.vue'
    import MaxScaleSelection from 'components/launchService/MaxScaleSelection.vue'
    import IopsAndThroughputSelection from 'components/launchService/IopsAndThroughputSelection.vue'
    import i18n from 'plugins/i18n'
    import { isStandAloneTopology, isPrimaryReplica, isColumnstore, isXpand, isProviderAWS, isProviderGCP, isServerlessTopology } from 'utils/service'
    import { isPowerTier } from 'utils/organization'

    export default {
        name: 'ConfigurationSelection',
        components: {
            Slider,
            AccordianPanelHeader,
            ReplicaSelector,
            IopsSelector,
            ServerInstanceList,
            InstanceTierUpgradeBanner,
            StorageAutoScale,
            NodeAutoScale,
            VolumeTypeSelection,
            MaxScaleSelection,
            IopsAndThroughputSelection,
        },
        mixins: [iopsComputation],
        props: {
            instanceData: {
                type: Array,
                required: true,
            },
            replicas: {
                type: Number,
                required: true,
            },
            defaultStorageSize: {
                type: Number,
                required: true,
            },
            isReplicaDisabled: {
                type: Boolean,
                default: false,
            },
            providerType: {
                type: String,
                default: '',
            },
            volumeTypes: {
                type: Array,
                default: () => [],
            },
            defaultIopsVolumeSize: {
                type: Number,
                default: null,
            },
            defaultIopsVolumeType: {
                type: String,
                default: null,
            },
            topologyRange: {
                type: Array,
                default: () => [],
            },
            showConfigPanel: {
                type: Boolean,
                required: true,
            },
            currentTier: {
                type: String,
                default: '',
            },
            topology: {
                type: String,
                required: true,
            },
            storageRange: {
                type: Array,
                required: true,
            },
            instanceCost: {
                type: Array,
                required: true,
            },
            isDisabled: {
                type: Boolean,
                default: true,
            },
            showReplicaWidget: {
                type: Boolean,
                default: true,
            },
            selectedInstance: {
                type: Object,
                default: () => { },
            },
            maxScaleInstances: {
                type: Array,
                default: () => [],
            },
        },
        data: function () {
            return {
                storageSize: this.defaultStorageSize,
                currentInstance: this.currentInstance,
                iopsMinValue: 1,
                iopsMaxValue: this.$config.IOPS_MAX,
                currentIopsValue: this.defaultIopsVolumeSize,
                iopsVolumeType: this.defaultIopsVolumeType,
            }
        },
        computed: {
            ...mapGetters([
                'autoVerticalSizes',
                'iopsValues',
                'isInstanceSizeFetching',
                'maxScaleNodes',
                'storageMetering',
                'usageMetering'
            ]),
            isAWSProvider() {
                return isProviderAWS(this.providerType)
            },
            isGCPProvider() {
                return isProviderGCP(this.providerType)
            },
            isFoundationTier() {
                return this.currentTier === this.$config.FOUNDATION_TIER
            },
            isMaxStorageSelected() {
                return this.storageSize === this.storageRange.at(-1)
            },
            canScaleHorizontal() {
                return !(isColumnstore(this.topology) || isStandAloneTopology(this.topology))
            },
            availableHorizontalScaleRange() {
                return this.canScaleHorizontal ? this.topologyRange.filter(val => val > this.replicas) : []
            },
            availableStorageSizeRange() {
                return this.storageRange.filter((size) => this.storageSize < size)
            },
            isNodes() {
                return this.$help.hasNodesInTopology(this.topology)
            },
            widgetTitle() {
                if (this.isServerless) return this.$t('storage')
                let title = i18n.t('launchService.instanceTitle')
                if (isStandAloneTopology(this.topology)) return title
                return title + ', ' + (this.isNodes ? i18n.tc('launchService.nodes', 2) : i18n.tc('launchService.replicas', 2))
            },
            currentNodes() {
                // For replicated topology the replicas can be zero, so the actual replica nodes will be less than 1
                // where the 1 represent the primary node
                return isPrimaryReplica(this.topology) ? this.replicas - 1 : this.replicas
            },
            getClusterName() {
                return isXpand(this.topology) ? this.$t('launchService.xpandCluster') : ''
            },
            checkIfColumnStore() {
                return isColumnstore(this.topology)
            },
            isMaxScaleApplicable() {
                return this.maxScaleNodes.length > 1
            },
            showMaxScale() {
                return this.isMaxScaleApplicable && isPowerTier(this.currentTier)
            },
            isGP3Selected() {
                return this.isAWSProvider && (this.iopsVolumeType ? this.iopsVolumeType == this.$config.iops.gp3 : this.defaultIopsVolumeType == this.$config.iops.gp3)
            },
            isServerless() {
                return isServerlessTopology(this.topology)
            },
            canServerlessManualScale() {
                return this.$unleash.isFeatureEnabled('enable-serverless-manual-scaling')
            },
        },
        watch: {
            defaultStorageSize(val) {
                if (this.isServerless) {
                    this.handleStorageSelection(val)
                }
            },
        },
        methods: {
            handleInstanceConfigSelection(instance) {
                this.currentInstance = instance
                this.$emit('onInstanceConfigSelect', instance)
            },
            handleStorageSelection(size) {
                this.storageSize = size
                this.computeIopsRange(size)
                this.$emit('onStorageSelect', size)
            },
            handleReplicaChange(count) {
                this.$emit('onReplicaChange', count)
            },
            handleIopsTypeChange(type) {
                this.iopsVolumeType = type
                this.$emit('onVolumeTypeChange', type)
                if (this.checkIfColumnStore) {
                    // For analytical service use storage 100
                    // to get the IOPS range
                    this.computeIopsRange(this.$config.ANALYTICAL_DEFAULT_STORAGE)
                }
            },
            handleIopsVolumeChange(volume) {
                this.$emit('onVolumeSizeChange', volume)
            },
            onAutoScaleTypeForNodeSelected(autoScaleType) {
                this.$emit('on-node-scale-type-selected', autoScaleType)
            },
            onAutoScaleValueForNodeSelected(autoScaleValue) {
                this.$emit('on-node-scale-value-selected', autoScaleValue)
            },
            onAutoStorageScaleSelected(storageValue) {
                this.$emit('on-txn-storage-selected', storageValue)
            },
            onAutoStorageToggle(isChecked) {
                this.$emit('on-auto-txn-storage-toggle', isChecked)
            },
            onAutoNodeToggle(isChecked) {
                this.$emit('on-auto-node-toggle', isChecked)
            },
            onMaxScaleToggle(isChecked) {
                this.$emit('on-maxscale-toggle', isChecked)
            },
            setMaxScaleNode(selectedMaxScaleNode) {
                this.$emit('on-maxscale-node-selection', selectedMaxScaleNode)
            },
            setMaxScaleSize(selectedMaxScaleSize) {
                this.$emit('on-maxscale-size-selection', selectedMaxScaleSize)
            },
            onIopsChange(val) {
                this.$emit('on-iops-change', val)
            },
            onThroughputChange(val) {
                this.$emit('on-throughput-change', val)
            },
        },
    }
</script>

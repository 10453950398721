<style lang="scss" scoped>
    .column-textarea {
        min-width: 250px;
    }
    .cp-col-selected {
        background-color: #f4fbf4;
    }
    .catindex {
        width: 90px;
    }
</style>
<template>
    <div>
        <base-dialog
            v-model="isDialogOpen"
            :should-dismiss-on-save="false"
            :on-save="updateTableContext"
            :on-cancel="confirmClose"
            :min-width="1024"
            :max-width="1024"
            :on-close="confirmClose"
            :should-prevent-auto-close="true">
            <template #title>
                <div class="font-weight-light text-h4 text-capitalize">
                    {{ $t('copilot.enhanceTable') }}:
                </div>
                <div class="text-h6 pb-6">
                    {{ tableName }}
                </div>
            </template>
            <template #body>
                <v-row class="mt-0 mb-4">
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('copilot.tableDescription') }}</label>
                        <v-textarea
                            v-model="tableDescription"
                            label=""
                            rows="4"
                            :placeholder="$t('description')"
                            outlined
                            hide-details />
                    </v-col>
                </v-row>
                <v-row class="mt-0 mb-4">
                    <v-col class="pt-0 pb-0">
                        <label class="text-uppercase">
                            {{ $tc('column', 2) }}
                            <CopilotInfoIcon :help-text="$t('copilot.columnsSelection')" />
                        </label>
                        <div class="mb-2">
                            <v-text-field
                                v-model="search"
                                outlined
                                dense
                                clearable
                                hide-details
                                :label="$t('filter')"
                                append-icon="mdi-magnify"
                                @click:clear="() => search = ''" />
                        </div>
                        <div class="cp-columns">
                            <v-data-table
                                class="data-table-full"
                                :headers="headers"
                                :items="columns"
                                :search="search"
                                hide-default-footer
                                disable-pagination
                                fixed-header>
                                <template #[`header.selection`]>
                                    <v-simple-checkbox
                                        color="primary"
                                        :value="areAllColumnsSelected"
                                        @input="toggleAllColumns" />
                                </template>
                                <template #[`header.categorical`]="{ header }">
                                    <div class="catindex text-center">
                                        {{ header.text }}
                                        <CopilotInfoIcon
                                            :help-text="$t('copilot.columnCategoricalInfo')"
                                            :max-width="480" />
                                    </div>
                                </template>
                                <template #item="{ item: column }">
                                    <tr :class="column.isSelected ? 'cp-col-selected' : ''">
                                        <td>
                                            <v-simple-checkbox
                                                color="primary"
                                                :value="column.isSelected"
                                                @input="toggleColumn(column)" />
                                        </td>
                                        <td style="white-space: nowrap;">
                                            <v-icon dense>
                                                mdi-table-column
                                            </v-icon>
                                            {{ column.name || 'n/a' }}
                                        </td>
                                        <td>{{ column.type || 'n/a' }}</td>
                                        <td class="text-center">
                                            <v-checkbox
                                                v-model="column.dataRole"
                                                value="CATEGORICAL" />
                                        </td>
                                        <td>
                                            <v-textarea
                                                v-model="column.description"
                                                class="column-textarea"
                                                label=""
                                                rows="2"
                                                :placeholder="$t('copilot.columnDescription')"
                                                outlined
                                                hide-details />
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-col>
                </v-row>
            </template>
            <template #actions="{ cancel, save }">
                <v-spacer />
                <v-btn
                    text
                    rounded
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ $t('close') }} </span>
                </v-btn>
                <v-btn
                    color="primary"
                    class="save"
                    style="min-width: 100px;"
                    :disabled="!isFormValid"
                    depressed
                    rounded
                    @click="save">
                    <span class="text-none"> {{ $t('save') }}</span>
                </v-btn>
            </template>
        </base-dialog>
        <ConfirmationModal
            ref="confirmCloseModal"
            message="copilot.dialogCloseConfirmation"
            :on-confirm="close" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import CopilotInfoIcon from 'components/copilot/InfoIcon.vue'
    import ConfirmationModal from 'components/common/ConfirmationModal.vue'

    export default {
        name: 'CopilotContextModal',
        components: {
            CopilotInfoIcon,
            ConfirmationModal,
        },
        data() {
            return {
                isDialogOpen: false,
                search: '',
                table: {},
                tableName: '',
                tableDescription: '',
                columns: [],
                initialColumnsSnapshot: [],
                headers: [
                    { text: '', value: 'selection', sortable: false, },
                    { text: this.$t('name'), value: 'name', sortable: false, },
                    { text: this.$t('type'), value: 'type', sortable: false, },
                    { text: this.$t('copilot.columnCategoricalIndex'), value: 'categorical', sortable: false, },
                    { text: this.$t('description'), value: 'description', sortable: false, }
                ],
            }
        },
        computed: {
            ...mapGetters([
                'selectedAgent'
            ]),
            isFormValid() {
                return !!this.tableDescription
            },
            areAllColumnsSelected() {
                return !this.columns.some(column => column.isSelected === false)
            },
        },
        methods: {
            ...mapActions([
                'editAgentContextTable',
                'editAgentContextTableColumns',
                'refreshAgents'
            ]),
            open(table) {
                this.table = table
                this.tableName = table.name
                this.tableDescription = table.description
                if (table.availableColumns) {
                    this.columns = table.availableColumns.map(column => {
                        const contextColumn = table.columns.find(col => col.name === column.name)
                        return {
                            ...(contextColumn || column),
                            isSelected: Boolean(contextColumn),
                        }
                    })
                }
                this.initialColumnsSnapshot = this.$lodash.cloneDeep(this.columns)
                this.isDialogOpen = true
            },
            toggleColumn(column) {
                column.isSelected = !column.isSelected
                if (!column.isSelected) {
                    column.dataRole = null
                }
            },
            toggleAllColumns(status) {
                this.columns.forEach(column => {
                    column.isSelected = status
                    if (!status) {
                        column.dataRole = null
                    }
                })
            },
            confirmClose() {
                if (this.$lodash.isEqual(this.initialColumnsSnapshot, this.columns)) {
                    this.isDialogOpen = false
                } else {
                    this.$refs.confirmCloseModal.open()
                }
            },
            close() {
                this.isDialogOpen = false
            },
            async updateTableContext() {
                let editStatus = true
                let columnsUpdatestatus = true
                if (this.table.description !== this.tableDescription) {
                    editStatus = this.editAgentContextTable({
                        contextId: this.table.contextId,
                        tableId: this.table.id,
                        tableDescription: this.tableDescription,
                    })
                    if (editStatus) {
                        this.table.description = this.tableDescription
                    }
                }
                if (!this.$lodash.isEqual(this.initialColumnsSnapshot, this.columns)) {
                    const columnsToUpdate = this.columns
                        .filter(column => column.isSelected)
                        .map(column => {
                            return {
                                name: column.name,
                                type: column.type,
                                description: column.description,
                                dataRole: column.dataRole,
                            }
                        })
                    columnsUpdatestatus = await this.editAgentContextTableColumns({
                        agentId: this.selectedAgent.id,
                        tableId: this.table.id,
                        columns: columnsToUpdate,
                    })
                }
                if (editStatus && columnsUpdatestatus) {
                    this.close()
                    this.refreshAgents()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .context-menu {
        height: 0px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        > div.tables-menu {
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .v-input ::v-deep .v-input__control {
            .v-input__slot {
                min-height: unset;
            }
            .v-input__append-inner {
                margin-top: 6px;
            }
            .v-label {
                font-size: 14px;
            }
        }
    }
    .context-schema {
        display: flex;
        justify-content: space-between;
    }
    .selected {
        background-color: #f4fbf4
    }
    .v-list-item {
        min-height: auto;
    }
    .table.v-list-item {
        .v-list-item__content {
            padding: 6px 0;
        }
        .actions {
            flex-direction: row;
            gap: 4px;
            align-items: center;
            margin: 0 12px 0 6px;
        }
    }
</style>

<template>
    <div v-if="isAgentContextLoading">
        <v-progress-linear
            indeterminate
            color="primary" />
    </div>
    <div
        v-else-if="tables.length"
        class="context-menu">
        <div>
            <CopilotContextModal ref="contextModal" />
            <v-text-field
                v-model="search"
                outlined
                dense
                clearable
                hide-details
                height="36"
                :label="$t('copilot.filterTables')"
                append-icon="mdi-magnify"
                @click:clear="() => search = ''" />
            <v-list-item
                class="px-0">
                <v-list-item-content class="pt-2 pb-0">
                    <v-list-item-title class="context-schema">
                        <div>
                            <v-icon>mdi-table-multiple</v-icon>
                            {{ agentContextSchema }}
                        </div>
                        <v-tooltip
                            v-if="!isInSync"
                            open-delay="400"
                            max-width="300"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    color="warning"
                                    x-small
                                    text
                                    :loading="isSyncingContext"
                                    v-on="on"
                                    @click="syncContext">
                                    <v-icon left>
                                        mdi-sync-alert
                                    </v-icon>
                                    {{ $t('copilot.outOfSync') }}
                                </v-btn>
                            </template>
                            <span>{{ $t('copilot.outOfSyncInfo') }}</span>
                        </v-tooltip>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <div style="height: 4px; margin-top: -4px;">
                <v-progress-linear
                    v-if="isSaving"
                    indeterminate
                    color="primary" />
            </div>
        </div>
        <div class="tables-menu">
            <v-list class="py-0">
                <v-list-item
                    v-for="(table, i) in filteredTables"
                    :key="i"
                    :class="['px-0 my-1 table', table.id ? 'selected' : '']">
                    <v-list-item-content>
                        <v-list-item-title :class="['pl-6', table.id ? 'accent--text' : '']">
                            <v-icon :color="table.id ? 'accent' : ''">
                                mdi-table
                            </v-icon>
                            <span class="text-body-2 pl-1">{{ table.name }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="actions">
                        <v-tooltip
                            v-if="table.id"
                            open-delay="400"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    color="primary"
                                    :disabled="isSaving"
                                    x-small
                                    icon
                                    @click="enhanceTable(table.id)"
                                    v-on="on">
                                    <v-icon>
                                        mdi-auto-fix
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('copilot.enhanceTable') }}</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="table.id"
                            open-delay="400"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    color="error"
                                    :disabled="isSaving"
                                    x-small
                                    icon
                                    v-on="on"
                                    @click="removeTable({ agentId: selectedAgent.id, tableId: table.id })">
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('copilot.removeTable') }}</span>
                        </v-tooltip>
                        <v-tooltip
                            v-else
                            open-delay="400"
                            top>
                            <template #activator="{ on }">
                                <v-btn
                                    color="accent"
                                    :disabled="isSaving"
                                    x-small
                                    icon
                                    v-on="on"
                                    @click="addTable({ agentId: selectedAgent.id, tableName: table.name })">
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('copilot.addTable') }}</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </div>
    </div>
    <div
        v-else
        class="text-center pt-4">
        {{ $t('copilot.emptySchema') }}
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import to from 'await-to-js'
    import { rebuildAgentContext } from 'services/copilot.js'
    import CopilotContextModal from 'components/copilot/ContextModal.vue'

    export default {
        name: 'CopilotContextMenu',
        components: {
            CopilotContextModal,
        },
        data () {
            return {
                search: '',
                isSaving: false,
                isSyncingContext: false,
            }
        },
        computed: {
            ...mapGetters([
                'isAgentContextLoading',
                'selectedAgent',
                'selectedDatasource'
            ]),
            agentContextSchema() {
                return this.selectedAgent?.context?.name
            },
            agentContextTables() {
                return this.selectedAgent?.context?.tables || []
            },
            isInSync() {
                return this.selectedAgent?.indexStatus === 'INSYNC'
            },
            tables() {
                const schema = this.selectedDatasource?.schemas?.find(schema => schema.name === this.agentContextSchema)
                if (!this.agentContextSchema || !schema || !schema.tables) {
                    return []
                } else {
                    return schema.tables.map(table => {
                        const contextTable = this.agentContextTables.find(contextTable => contextTable.name === table)
                        return {
                            id: contextTable ? contextTable.id : null,
                            name: table,
                        }
                    })
                }
            },
            filteredTables() {
                return this.tables
                    .filter(table => table.name.toLowerCase().includes(this.search?.toLowerCase() || ''))
                    .sort((a, b) => b.id === null ? -1 : 1)
            },
        },
        methods: {
            ...mapActions([
                'removeAgentContextTable',
                'addAgentContextTable',
                'refreshAgents'
            ]),
            async addTable(payload) {
                this.isSaving = true
                await this.addAgentContextTable(payload)
                const table = this.agentContextTables.find(contextTable => contextTable.name === payload.tableName)
                if (table) {
                    this.enhanceTable(table.id)
                }
                this.isSaving = false
                this.refreshAgents()
            },
            async removeTable(payload) {
                this.isSaving = true
                await this.removeAgentContextTable(payload)
                this.isSaving = false
                this.refreshAgents()
            },
            enhanceTable(tableId) {
                const table = this.agentContextTables.find(contextTable => contextTable.id === tableId)
                this.$refs.contextModal.open(table)
            },
            async syncContext() {
                this.isSyncingContext = true
                const [error] = await to(rebuildAgentContext(this.selectedAgent.id))
                if (!error) {
                    this.$store.commit('showMessage', { text: this.$t('success.generic'), type: this.$config.messageType.SUCCESS, })
                }
                await this.refreshAgents()
                this.isSyncingContext = false
            },
        },
    }
</script>

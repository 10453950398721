<style lang="scss" scoped>
.control-label {
    font-size: 0.675rem;
}
</style>

<template>
    <div class="mt-6">
        <v-row class="mb-0">
            <v-col
                cols="11"
                sm="5">
                <label class="d-block color text-small-text control-label">
                    {{ $t('billings.invoiceMonth') }}
                </label>
                <MonthYearPicker
                    @change="getHistoricalBills" />
            </v-col>
        </v-row>
        <v-container v-if="!isFetchingHistoricalBills">
            <template v-if="historicalBills && !historicalBillsErrors">
                <!-- summary -->
                <HistoricalBillSummary
                    :historical-bills="historicalBills" />

                <!-- usage table -->
                <div
                    class="mt-4">
                    <BillingUsageTable
                        :current-usage-sort="currentUsageSort"
                        :usage-data="usageData"
                        :is-one-level-tree="false" />
                    <v-row>
                        <v-col cols="8" />
                        <BillingUsageTotal
                            :total="total" />
                        <v-col cols="2" />
                    </v-row>
                </div>
            </template>
            <template v-else>
                <v-row
                    justify="center"
                    class="font-weight-bold text-subtitle-1 pa-2">
                    {{ $t('errors.noData') }}
                </v-row>
            </template>
        </v-container>
        <v-container
            v-else
            class="fill-height mt-8"
            fluid>
            <v-row
                align="center"
                justify="center">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import i18n from 'plugins/i18n'
    import HistoricalBillSummary from 'components/billing/HistoricalBillSummary.vue'
    import BillingUsageTable from 'components/billing/BillingUsageTable.vue'
    import BillingUsageTotal from 'components/billing/BillingUsageTotal.vue'
    import MonthYearPicker from 'components/common/MonthYearPicker.vue'
    import { getTopologyTitle } from 'utils/billing'

    export default {
        name: 'HistoricalUsage',
        components: {
            BillingUsageTable,
            BillingUsageTotal,
            MonthYearPicker,
            HistoricalBillSummary,
        },
        data() {
            return {
                currentUsageSort: 'service',
            }
        },
        computed: {
            ...mapGetters([
                'isFetchingHistoricalBills',
                'historicalBillsErrors',
                'historicalBills'
            ]),
            usageData(){
                if(this.$lodash.isEmpty(this.historicalBills?.lineItems)) return []
                return this.transformUsageByServices()
            },
            total() {
                return this.historicalBills?.total ?? 0
            },
        },
        methods: {
            ...mapActions(['fetchHistoricalBills']),
            updateUsageSort(sortType) {
                this.currentUsageSort = sortType
            },
            getHistoricalBills(monthYear) {
                this.fetchHistoricalBills(monthYear)
            },
            transformUsageByServices() {
                let usage = []
                let usageData = this.historicalBills?.lineItems
                usageData?.forEach(o => {
                    let obj = {}
                    let data = this.getChildData(o)
                    obj.title = o?.service?.name ?? '',
                    obj.subTitle = i18n.t('billings.topologyOnProvider', { topology: getTopologyTitle(o?.service?.topology), provider: o?.service?.provider.toUpperCase(), }),
                    obj.cost = data.total
                    obj.children = data.children
                    usage.push(obj)
                })
                usage.push({
                    title: i18n.t('billings.dataTransferCost'),
                    subTitle: i18n.t('billings.accumulatedMessage'),
                    cost: this.historicalBills?.dataTransferCost,
                    children: [],
                })
                usage.push({
                    title: i18n.t('billings.supportCost'),
                    subTitle: i18n.t('billings.supportType',[this.historicalBills?.supportType]),
                    cost: this.historicalBills?.supportCost,
                    children: [],
                })
                return usage
            },
            getChildData(lineItem) {
                let total = 0
                let children = []

                for (let instance in lineItem?.cost) {
                    let obj = {}
                    let instanceTotal = 0
                    let items = lineItem?.cost[instance] || []
                    obj.title = items[0]?.instance
                        ? (items[0]?.instance.charAt(0).toUpperCase() + items[0]?.instance.slice(1))
                        : ''
                    obj.children = []
                    items.forEach(node => {
                        instanceTotal += node?.totalCost
                        obj.children.push({
                            title: node?.name == 'Compute' ? i18n.t('billings.nodes') : node?.name,
                            ...this.getAttributes(node),
                        })
                    })
                    obj.cost = instanceTotal
                    children.push(obj)
                    total += instanceTotal
                }

                return {children, total,}
            },
            getAttributes(object) {
                let res = {
                    runtime: object?.totalHours,
                    unit: i18n.t('billings.hours'),
                    perMinCost: object?.pricePerHour,
                    cost: object?.totalCost,
                    rateUnit: i18n.t('billings.hours'),
                }
                if (object?.name == 'Compute') {
                    res.activeNodes = object?.activeNodes
                }
                if (object?.name == 'Storage') {
                    res.hideRateDetails = true
                }
                return res
            },
        },
    }
</script>

<style lang="scss" scoped>
    .provider {
        border: 2px solid #bed1da;
        margin: 0 -2px;
        position: relative;
        .selection-status {
            position: absolute;
            right: 0;
            top: 2px;
        }
        .coming-soon {
            position: absolute;
            top: 0;
            right:0;
            background: $selection-highlight;
            border-radius: 0px 10px;
            padding: 4px;
            font-weight: 600;
            font-size: 9px;
            color: $background
        }
    }
    .disabled-provider :not(.coming-soon){
        opacity: 0.7;
    }
    .left-card {
        border-radius: 10px 0px 0px 10px;
        margin: 0;
    }
    .right-card {
        border-radius: 0px 10px 10px 0px;
        margin: 0;
    }
    .tech-preview {
        position: absolute;
        top: 14px;
        right: 24px;
    }
</style>
<template>
    <v-col
        :class="[
            'pt-8 pb-8 provider pointer',
            border === 'right' && 'right-card' ,
            border === 'left' && 'left-card',
            isSelected && 'highlight-instance-row',
            isDisabled && 'disabled-provider'
        ]"
        @click="onClick">
        <div
            v-if="isDisabled"
            class="coming-soon text-uppercase font-weight-medium">
            {{ $t('comingSoon') }}
        </div>
        <div
            v-if="isSelected"
            class="selection-status">
            <v-icon size="20">
                $vuetify.icons.statusOk
            </v-icon>
        </div>
        <TechPreview v-if="isTechPreview" />
        <div class="text-center">
            <v-icon
                style="height: 26px"
                :size="iconSize">
                {{ icon }}
            </v-icon>
        </div>
    </v-col>
</template>
<script>
    import TechPreview from 'components/common/TechPreview.vue'

    const CloudServiceProvider = {
        name: 'cloud-service-provider',
        components: { TechPreview, },
        props: {
            isSelected: Boolean,
            icon: String,
            iconSize: Number,
            type: String,
            border: String,
            isDisabled: {
                type: Boolean,
                default: false,
            },
            isTechPreview: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            onClick () {
                if(!this.isDisabled) this.$emit('handleCloudProviderSelection', this.type)
            },
        },
    }

    export default CloudServiceProvider
</script>

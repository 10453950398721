import { CONFIG } from 'config/'
export function getClass(status, isReplica) {
    const { FAILED, RETIRED, READY, STOPPED, } = CONFIG.serviceStatus
    let prefix = 'pending'
    switch (status) {
    case READY:
        prefix = 'active'
        break
    case FAILED:
    case RETIRED:
        prefix = 'failed'
        break
    case STOPPED:
        prefix = 'stopped'
        break
    }
    return isReplica ? `${prefix}-replica` : `${prefix}-current`
}
export function getTopologyInboundStatus(topologiesData, replica, service) {
    const { TO, } = CONFIG.REPLICATION_DIRECTION_OPTIONS
    let inboundService = replica.direction === TO ? replica.service : service
    let topology = topologiesData.find(topology => topology.namespace === inboundService.id)
    if (!topology || !topology.inboundReplication.status) return CONFIG.REPLICATION_STATUS.PENDING
    return topology.inboundReplication.status
}

export function getLag(topologiesData, replica, service) {
    const { TO, } = CONFIG.REPLICATION_DIRECTION_OPTIONS
    let inboundService = replica.direction === TO ? replica.service : service
    let topology = topologiesData.find(topology => topology.namespace === inboundService.id)
    if (topology) {
        const { secondsBehindPrimary, } = topology.inboundReplication
        // Lag value is available ONLY when Inbound status is "OK"
        return getTopologyInboundStatus(topologiesData, replica, service) !== CONFIG.REPLICATION_STATUS.OK ? '--' : `${secondsBehindPrimary}s`
    } else {
        return '--'
    }
}
export function getReplicas(services, service = {}) {
    let reps = []
    const { TO, FROM, BOTH_WAYS, } = CONFIG.REPLICATION_DIRECTION_OPTIONS
    services.forEach(serviceObj => {
        let obj = {}, { primary_host: primaryHost, id, } = service
        if (primaryHost == serviceObj.id || serviceObj.primary_host === id) {
            obj['direction'] = serviceObj.primary_host === id ? (primaryHost == serviceObj.id ? BOTH_WAYS : TO) : FROM
            obj['service'] = serviceObj
            reps.push(obj)
        }
    })
    return reps
}
export function getColor(status) {
    const { FAILED, RETIRED, READY, STOPPED, } = CONFIG.serviceStatus
    let color = 'grayed-out'
    switch (status) {
    case READY:
        color = 'success'
        break
    case FAILED:
    case RETIRED:
        color = 'deep-ocean'
        break
    case STOPPED:
        color = 'error'
        break
    }
    return color
}
export function isModifying(replica, service) {
    const { PENDING_MODIFYING, } = CONFIG.serviceStatus
    return [replica.service.status, service.status].includes(PENDING_MODIFYING)
}
export function getReplicationStatus(topologiesData, replicas, service) {
    const { MODIFYING, PENDING, CRITICAL, OK, } = CONFIG.REPLICATION_STATUS
    return replicas.some(replica => isModifying(replica, service)) ? MODIFYING :
        replicas.some(replica => getTopologyInboundStatus(topologiesData, replica, service) === PENDING) ? PENDING :
            replicas.some(replica => getTopologyInboundStatus(topologiesData, replica, service) === CRITICAL) ? CRITICAL : OK
}
export function getStrokeStyle(topologiesData, replica, service, lightTheme) {
    const { error, 'blue-azure': blueAzure, 'grayed-out': grayedOut, } = lightTheme
    const { PENDING, CRITICAL, } = CONFIG.REPLICATION_STATUS
    if (isModifying(replica, service)) return grayedOut
    switch (getTopologyInboundStatus(topologiesData, replica, service)) {
    case PENDING:
        return grayedOut
    case CRITICAL:
        return error
    default:
        return blueAzure
    }
}

import Copilot from 'pages/Copilot.vue'

export default [
    {
        path: '/skyai-agents/:agentId?/:datasourceId?',
        name: 'copilot',
        component: Copilot,
        meta: {
            requiresAuth: true,
            title: 'SkyAI Agents',
        },
    }
]

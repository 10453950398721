<style lang="scss" scoped>
    .scale-reduce {
        transform: scale(0.625);
        transform-origin: 50% 80%;
    }
    .inactive {
        background: #f6f4f4;
    }
    ::v-deep .selected-row {
        background: $light-cyan;
    }
    tr:hover {
        .copy-rule {
            visibility: visible;
        }
    }
</style>

<template>
    <div>
        <table-actions
            :loading="loading"
            :selected-items="selectedItems"
            :delete-modal-title="`${$t('confirmations.alertDeleteTitle', {
                quantity: selectedItems.length,
                targetType: rulePlural
            })}`"
            :delete-modal-msg="$t('confirmations.alertDeleteMsg', { targetType: rulePlural })"
            delete-modal-item-key-name="alert"
            @add-new="addNewRule"
            @on-delete="deleteSelected"
            @on-cancel-delete="handleSelect(false)" />
        <v-data-table
            :headers="headers"
            fixed-header
            :height="$config.alertManagerTableHeight"
            :items="rules"
            :loading="loading"
            hide-default-footer
            disable-pagination
            item-key="alert"
            class="data-table-full">
            <template #no-data>
                <span>{{ $t('info.noAlertingRules') }} -</span>
                <span
                    class="ml-1 color pointer text-links"
                    @click="addNewRule">{{ $t('addRule') }}</span>
            </template>
            <template #[`header.select`]>
                <v-checkbox
                    class="scale-reduce ma-0"
                    :input-value="isAllSelected"
                    primary
                    :disabled="!rules.length"
                    @change="handleSelect" />
            </template>
            <template #item="{ item: rule }">
                <tr :class="[{ 'selected-row': rule.selected, inactive: !rule.active }]">
                    <!-- {{ rule }} -->
                    <td>
                        <v-checkbox
                            v-model="rule.selected"
                            class="scale-reduce ma-0"
                            :input-value="rule.selected"
                            primary />
                    </td>
                    <td>
                        <a
                            class="color text-text text-links"
                            @click="editRule(rule)">
                            {{ rule.name }}
                        </a>
                    </td>
                    <td>
                        <tag-listing
                            :tags="_tags(rule)"
                            small-tags
                            disable-delete-tag
                            :show-tags-count="6" />
                    </td>
                    <td>
                        <div class="d-inline-flex align-center">
                            <v-switch
                                v-model="rule.active"
                                class="scale-reduce ma-0"
                                small
                                :disabled="loading"
                                @change="onRuleChange(rule)" />
                            <v-tooltip top>
                                <template #activator="{ on }">
                                    <v-btn
                                        small
                                        icon
                                        color="primary"
                                        class="ml-5 visibility--none copy-rule"
                                        v-on="on"
                                        @click="() => copyRule(rule)">
                                        <v-icon
                                            size="18px"
                                            color="primary">
                                            $vuetify.icons.copy
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('duplicateThisRule') }}</span>
                            </v-tooltip>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <rule-modal ref="ruleModal" />
    </div>
</template>

<script>
    import RuleModal from 'components/alerts/RuleModal'
    import TableActions from 'components/alerts/TableActions'

    export default {
        name: 'RulesTable',
        components: {
            'rule-modal': RuleModal,
            'table-actions': TableActions,
        },
        props: {
            rules: { type: Array, required: true, },
            loading: { type: Boolean, default: false, },
        },
        data () {
            return {
                headers: [
                    { text: 'select', value: 'select', sortable: false, width: '20px', },
                    { text: 'policy', value: 'alert', width: '450px',sortable: false,},
                    { text: 'tags', value: 'tags', sortable: false, },
                    { text: 'active', value: 'active', width: '1%', sortable: false,}
                ],
                dupeSuffixRegex: /(copy ?\d*$)/g,
            }
        },
        computed: {
            selectedItems: function () {
                return this.rules.filter((rule) => rule.selected)
            },
            isAllSelected: function () {
                return this.rules.length > 0 && this.selectedItems.length === this.rules.length
            },
            rulePlural: function () {
                return this.selectedItems.length > 1 ? this.$tc('rule', 2) : this.$tc('rule', 1)
            },
            allRuleNames: function () {
                return this.rules.map(rule => rule.alert)
            },
        },
        methods: {
            _tags (rule) {
                const alertTagKeys = [this.$config.customTagPrefix, 'context']
                let tags = this.$help.objects.pickPropertyStartsWith({ object: rule.labels, keys: alertTagKeys, })
                tags.context = rule.context
                if (this.$typy(tags, 'context').isDefined) tags.context = this.$help.alerts.unescapeTags(tags.context)
                return this.$help.alerts.cortexLabelsToTags(tags)
            },
            getDuplicateIncrement (allClones) {
                let increments = []
                allClones.forEach(name => {
                    const stem = name.replace(this.dupeSuffixRegex, '').trim()
                    const dupeSuffIncr = name.replace(stem, '')
                    let i = dupeSuffIncr.replace(/[^\d]/g, '')
                    if (i) increments.push(Number(i))
                    else increments.push(0)
                })
                return Math.max(...increments)
            },
            ruleUID (targetName) {
                const dupeSuffix = 'copy'
                const stem = targetName?.replace(this.dupeSuffixRegex, '').trim() || ''
                const allClones = this.allRuleNames.filter(name => {
                    return name !== stem && name.includes(stem) && name.includes(dupeSuffix)
                })
                if (allClones.length) {
                    const currentMax = this.getDuplicateIncrement(allClones)
                    return `${stem} ${dupeSuffix} ${currentMax + 1}`
                } else return `${stem} ${dupeSuffix}`
            },
            handleSelect (val) {
                this.rules.forEach(rule => (rule.selected = val))
            },
            async deleteSelected () {
                this.$emit('rule-delete', this.rules.filter(rule => rule.selected))
            },
            openRuleModal ({ mode, rule, newCopiedRule, }) {
                this.$refs.ruleModal.open({ mode, rule, newCopiedRule, })
            },
            onRuleChange (rule) {
                this.$emit('rule-edit', rule)
            },
            editRule (rule) {
                this.openRuleModal({ mode: 'edit', rule, })
            },
            addNewRule () {
                this.openRuleModal({ mode: 'create', })
            },
            copyRule (ruleTobeCopied) {
                let newRule = this.$help.objects.clone(ruleTobeCopied)
                newRule.alert = this.ruleUID(newRule.alert)
                this.openRuleModal({ mode: 'copy', rule: ruleTobeCopied, newCopiedRule: newRule, })
            },
        },
    }
</script>

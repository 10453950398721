var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{class:[
        'pt-8 pb-8 provider pointer',
        _vm.border === 'right' && 'right-card' ,
        _vm.border === 'left' && 'left-card',
        _vm.isSelected && 'highlight-instance-row',
        _vm.isDisabled && 'disabled-provider'
    ],on:{"click":_vm.onClick}},[(_vm.isDisabled)?_c('div',{staticClass:"coming-soon text-uppercase font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('comingSoon'))+" ")]):_vm._e(),(_vm.isSelected)?_c('div',{staticClass:"selection-status"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" $vuetify.icons.statusOk ")])],1):_vm._e(),(_vm.isTechPreview)?_c('TechPreview'):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"height":"26px"},attrs:{"size":_vm.iconSize}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
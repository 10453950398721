<style>
.max-height-11 {
    max-height: 11em !important
}
</style>

<template>
    <v-row justify="center">
        <v-dialog
            v-model="isDialogOpen"
            persistent
            max-width="800px"
            scrollable>
            <v-card>
                <v-card-title class="pt-7 px-10">
                    <span class="text-h4 font-weight-medium">
                        {{ 'Restore backup' }}
                    </span>
                </v-card-title>
                <v-card-text class="overflow-visible">
                    <v-container style="min-height: 200px;">
                        <v-row>
                            <v-col cols="6">
                                <div>
                                    <label class="text-uppercase">
                                        {{ $t('backups.sourceService') }}
                                    </label>
                                    <div class="text-body-1">
                                        {{ backup.serviceName }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div>
                                    <label class="text-uppercase">
                                        {{ $t('backups.timeOfBackup') }}
                                    </label>
                                    <div class="text-body-1">
                                        {{ $moment(backup.endTime).format('DD-MM-YYYY hh:mm:ss a') }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                :cols="backup?.type?.toLowerCase() === 'binlog' ? '5' : '8'">
                                <label class="text-uppercase required">
                                    {{ $t('backups.targetService') }}
                                </label>
                                <div>
                                    <v-select
                                        v-model="selectedServiceId"
                                        :items="servicesList"
                                        class="std std--dropdown"
                                        :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list', maxHeight: '200px' }"
                                        height="40"
                                        outlined
                                        item-value="serviceId"
                                        item-text="serviceName"
                                        attach
                                        required
                                        hide-details />
                                </div>
                            </v-col>
                            <v-col
                                v-if="backup?.type?.toLowerCase() === 'binlog'"
                                cols="7"
                                class="pb-0">
                                <label class="text-uppercase">
                                    {{ $t('backups.pointInTime') + ' (CCYY-MM-DD hh:mm:ss)' }}
                                </label>
                                <v-text-field
                                    v-model="pointInTime"
                                    outlined
                                    :placeholder="'CCYY-MM-DD hh:mm:ss'"
                                    :rules="[validateDateTime]"
                                    dense
                                    :disabled="backup?.type?.toLowerCase() !== 'binlog'" />
                            </v-col>
                            <v-col
                                v-if="backup?.isExternalStorage"
                                cols="7"
                                class="pt-0">
                                <label class="text-uppercase">
                                    {{ $t('storage') }}
                                </label>
                                <v-radio-group
                                    v-model="storage"
                                    row
                                    dense
                                    hide-details
                                    class="mt-0">
                                    <v-radio
                                        label="Managed"
                                        value="managed" />
                                    <v-radio
                                        label="External"
                                        value="external" />
                                </v-radio-group>
                            </v-col>
                            <v-col
                                v-if="storage === 'external'"
                                cols="5"
                                class="pt-0">
                                <label class="required text-uppercase">{{ 'Restore Method' }}</label>
                                <v-select
                                    v-model="selectedMethod"
                                    :items="restoreMethods"
                                    item-value="id"
                                    class="std std--dropdown"
                                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                                    height="36"
                                    outlined
                                    attach
                                    required
                                    hide-details>
                                    <template #item="{ item }">
                                        {{ item.value }}
                                    </template>
                                    <template #selection="{ item }">
                                        {{ item.value }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col
                                v-if="storage === 'external'"
                                cols="12">
                                <label class="text-uppercase">
                                    {{ $t('launchService.cloudProvider') }}
                                </label>
                                <v-radio-group
                                    v-model="provider"
                                    row
                                    class="mt-0">
                                    <v-radio
                                        label="AWS"
                                        value="aws" />
                                    <v-radio
                                        label="GCP"
                                        value="gcp" />
                                </v-radio-group>
                            </v-col>
                            <template v-if="storage === 'external'">
                                <v-col
                                    cols="12"
                                    class="py-0">
                                    <label class="required text-uppercase">
                                        {{ $t('backups.storagePath') }}
                                    </label>
                                    <v-text-field
                                        v-model="path"
                                        :placeholder="provider === 'aws' ? 's3://my_backup_bucket' : 'gs://my_backup_bucket'"
                                        outlined
                                        dense />
                                </v-col>
                                <template v-if="provider === 'aws'">
                                    <v-col
                                        cols="7"
                                        class="py-0">
                                        <label class="required text-uppercase">
                                            {{ $t('backups.accessKeyId') }}
                                        </label>
                                        <v-text-field
                                            v-model="accessKeyId"
                                            :placeholder="$t('backups.accessKeyId')"
                                            outlined
                                            dense />
                                    </v-col>
                                    <v-col
                                        cols="5"
                                        class="pt-0">
                                        <label class="required text-uppercase">
                                            {{ $tc('region') }}
                                        </label>
                                        <div>
                                            <v-select
                                                v-model="selectedRegion"
                                                :items="regionList"
                                                class="std std--dropdown"
                                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-11' }"
                                                height="40"
                                                outlined
                                                attach
                                                required
                                                hide-details>
                                                <template #item="{ item }">
                                                    {{ item }}
                                                </template>
                                                <template #selection="{ item }">
                                                    {{ item }}
                                                </template>
                                            </v-select>
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0">
                                        <label class="required text-uppercase">
                                            {{ $t('backups.accessKey') }}
                                        </label>
                                        <v-text-field
                                            v-model="accessKey"
                                            :placeholder="$t('backups.accessKey')"
                                            outlined
                                            hide-details
                                            dense />
                                    </v-col>
                                </template>
                                <template v-else>
                                    <v-col
                                        cols="12"
                                        class="py-0">
                                        <label class="required text-uppercase">
                                            {{ `${$t('backups.serviceAccountKey')} (JSON)` }}
                                        </label>
                                        <v-textarea
                                            v-model="serviceAccountKey"
                                            :placeholder="'{\n  &quot;type&quot;: &quot;service_account&quot;,\n  ...\n}'"
                                            outlined
                                            dense
                                            hide-details />
                                    </v-col>
                                </template>
                                <v-col
                                    cols="4"
                                    class="py-0">
                                    <v-checkbox
                                        v-model="isBackupEncrypted"
                                        :label="$t('backups.encryptedBackup')"
                                        dense />
                                </v-col>
                                <v-col
                                    v-if="isBackupEncrypted"
                                    cols="12"
                                    class="py-0">
                                    <label class="required text-uppercase">
                                        {{ $t('backups.encryptionKey') }}
                                    </label>
                                    <v-text-field
                                        v-model="encryptionKey"
                                        :placeholder="$t('backups.encryptionKey')"
                                        outlined
                                        dense
                                        hide-details />
                                </v-col>
                            </template>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions class="px-9 py-4">
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="onClose">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        class="text-none"
                        color="primary"
                        style="min-width: 100px;"
                        rounded
                        depressed
                        :ripple="false"
                        :disabled="!isFormValid || selectedServiceId?.length < 1"
                        :loading="isLoading"
                        @click="isConfirmDialogOpen = true">
                        {{ $t('restore') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="isConfirmDialogOpen"
            persistent
            max-width="600px"
            scrollable>
            <v-card>
                <v-card-title class="pt-9 pb-5 px-9">
                    <span class="text-h4 font-weight-medium">
                        {{ $t('confirmation') }}</span>
                </v-card-title>
                <v-card-text class="pb-5 px-9">
                    <span
                        class="text-body-1 red--text">
                        {{ $t('warn.createRestoreWarning') }}</span>
                </v-card-text>
                <v-divider />
                <v-card-actions class="py-4 px-9">
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        outlined
                        :ripple="false"
                        @click="isConfirmDialogOpen = false">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        class="text-none"
                        color="primary"
                        rounded
                        depressed
                        :ripple="false"
                        @click="onSubmit">
                        {{ $t('confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import to from 'await-to-js'
    import { getRestoreTargets } from 'services/backups.js'

    export default {
        name: 'BackupRestoreModal',
        props: {
            showDialog: {
                type: Boolean,
                required: true,
            },
            isLoading: {
                type: Boolean,
            },
            backup: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isDialogOpen: false,
                isConfirmDialogOpen: false,
                servicesList: [],
                selectedServiceId: '',
                storage: 'managed',
                provider: 'aws',
                path: '',
                accessKeyId: '',
                accessKey: '',
                selectedRegion: '',
                serviceAccountKey: '',
                restoreMethods: [{id: 'mysqldump', value: 'mysqldump',},{id: 'mariabackup', value: 'Mariabackup',}],
                selectedMethod: '',
                isBackupEncrypted: false,
                encryptionKey: '',
                pointInTime: '',
                validateDateTime: value => {
                    const pattern = /^([0-2][0-9]{3})\-(0[1-9]|1[0-2])\-([0-2][0-9]|3[0-1]) ([0-1][0-9]|2[0-3]):([0-5][0-9])\:([0-5][0-9])( ([\-\+]([0-1][0-9])\:00))?$/
                    if (value === '' || (pattern.test(value) && new Date().getTime() < new Date(value).getTime())) return true
                    else return 'Invalid input'
                },
            }
        },
        computed: {
            ...mapGetters(['regions', 'services']),
            isFormValid() {
                if (this.storage === 'managed') return true
                else {
                    if (this.isBackupEncrypted && this.encryptionKey === '') return false
                    if (this.selectedMethod === '') return false
                    if (this.provider === 'aws')
                        return this.path !== '' && this.accessKey !== '' && this.accessKeyId !== '' && this.selectedRegion !== ''
                    else
                        return this.path !== '' && this.serviceAccountKey !== ''
                }
            },
            regionList() {
                return this.regions?.filter(region => region?.provider?.toLowerCase() === 'aws')?.map(region => region?.name)
            },
        },
        watch: {
            showDialog() {
                this.isDialogOpen = this.showDialog
                if (this.isDialogOpen) {
                    this.loadData()
                }
            },
        },
        methods: {
            ...mapActions(['getRegions']),
            async getRestoreServices() {
                try {
                    const [error, services] = await to(getRestoreTargets({ backupId: this.backup?.id, }))
                    if (!error) {
                        if (this.$typy(services).isArray) {
                            this.servicesList = services
                        }
                    }
                } catch (error) {
                    // do nothing
                }
            },
            onClose() {
                this.$emit('on-close')
            },
            onSubmit() {
                this.isConfirmDialogOpen = false
                const obj = {
                    serviceId: this.selectedServiceId,
                    backupId: this.backup?.id,
                    storage: this.storage,
                    ...(this.pointInTime !== '' && {pointInTime: this.pointInTime,}),
                    ...(this.storage === 'external' && {provider: this.provider,}),
                    ...(this.storage === 'external' && this.provider == 'aws' && {path: this.path, accessKeyId: this.accessKeyId, accessKey: this.accessKey, region: this.selectedRegion,}),
                    ...(this.storage === 'external' && this.provider == 'gcp' && {path: this.path, serviceAccountKey: this.serviceAccountKey,}),
                    ...(this.storage === 'external' && {method: this.selectedMethod,}),
                    ...(this.storage === 'external' && this.isBackupEncrypted && {encryptionKey: this.encryptionKey,}),
                }
                this.$emit('on-restore-submit', obj)
            },
            loadData() {
                this.getRestoreServices()

                if (!this.regions?.length) {
                    this.getRegions()
                }
            },
        },
    }
</script>

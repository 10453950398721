<style lang="scss" scoped>
    pre {
        white-space: pre-wrap;
    }
</style>

<template>
    <v-snackbar
        v-model="message.status"
        :color="message.type"
        :vertical="message.vertical"
        :bottom="true"
        :right="true"
        :timeout="message.timeout * 1000">
        <div v-if="message.type !== 'error'">
            {{ message.text }}
        </div>
        <pre v-else>{{ message.text }}</pre>
        <div
            v-if="message.footerText?.length"
            class="mt-6">
            {{ message.footerText }}
        </div>
        <template #action="{ attrs }">
            <template v-if="message.vertical">
                <v-btn
                    v-for="(btn, index) in message.actions"
                    :key="index"
                    text
                    v-bind="attrs"
                    @click="btn.handler">
                    {{ btn.text }}
                </v-btn>
                <v-btn
                    text
                    v-bind="attrs"
                    @click="message.status = false">
                    {{ $t('close') }}
                </v-btn>
            </template>
            <template v-else>
                <v-btn
                    class="mr-0"
                    v-bind="attrs"
                    text
                    icon
                    @click="message.status = false">
                    <v-icon color="white">
                        mdi-close
                    </v-icon>
                </v-btn>
            </template>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'Snackbars',
        computed: {
            message () {
                return this.$store.state.message
            },
        },
    }
</script>

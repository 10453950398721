<style lang="scss" scoped>
    #copilot-queries {
        position: relative;
        font-size: 16px;
    }
</style>

<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-cancel="close"
        :min-width="760"
        :max-width="900"
        :on-close="close">
        <template #title>
            <div class="font-weight-light text-h4 pb-6">
                {{ $t('copilot.manageGoldenQueries') }}
            </div>
        </template>
        <template #body>
            <div
                id="copilot-queries"
                ref="queries">
                <div v-if="!queries.length">
                    <v-alert
                        outlined
                        type="info"
                        text>
                        {{ $t('info.copilotNoGoldenQueries') }}
                    </v-alert>
                </div>
                <div v-else>
                    <template
                        v-for="(entry, i) in queries">
                        <CopilotChatMessage
                            :key="entry.id"
                            :message="entry"
                            session-id=""
                            mode="manage"
                            class="copilot-message" />
                        <v-divider
                            v-if="i < queries.length - 1"
                            :key="i"
                            class="mt-8 mb-8" />
                    </template>
                </div>
            </div>
        </template>
        <template #actions="{ cancel }">
            <v-spacer />
            <v-btn
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn
                color="primary"
                style="min-width: 100px;"
                depressed
                rounded
                @click="addNew">
                <span class="text-none">{{ $t('addNew') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import CopilotChatMessage from 'components/copilot/ChatMessage.vue'

    export default {
        name: 'CopilotGoldenQueriesManageModal',
        components: {
            CopilotChatMessage,
        },
        data() {
            return {
                isDialogOpen: false,
            }
        },
        computed: {
            ...mapGetters([
                'selectedAgent'
            ]),
            queries() {
                return this.selectedAgent?.queries || []
            },
        },
        methods: {
            ...mapActions([
                'addTempAgentQuery',
                'clearTempAgentQueries'
            ]),
            open() {
                this.isDialogOpen = true
            },
            close() {
                this.clearTempAgentQueries(this.selectedAgent?.id)
                this.isDialogOpen = false
            },
            addNew() {
                this.addTempAgentQuery(this.selectedAgent?.id)
                setTimeout(() => {
                    const queries = this.$refs.queries.querySelectorAll('.copilot-message')
                    if (queries.length) {
                        queries[0].scrollIntoView()
                    }
                }, 100)
            },
        },
    }
</script>

<style lang="scss" scoped>
.billing-summary {
    border: 1px solid $cornsilk;
    color: $deep-ocean;
}
.separator {
    width: 100%;
    height: 1px;
    border-top: 1px solid $cornsilk;
    margin: 8px 0;
}
</style>

<template>
    <div>
        <v-row class="billing-summary pa-2">
            <v-col cols="2">
                {{ $t('billings.usageDays') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ historicalBills?.usageDays }}
                </div>
            </v-col>
            <v-col cols="2">
                {{ $t('billings.computeCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalComputeCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                {{ $t('billings.storageCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.totalStorageCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                {{ $t('billings.dataTransferCost') }}
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.dataTransferCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                <div class="align-center d-flex">
                    <span class="mr-1">
                        {{ $t('billings.supportCost') }}
                    </span>
                    <HelpToolTip
                        :size="16">
                        <template #message>
                            <p>
                                {{ $t('billings.supportType',[historicalBills?.supportType]) }}
                            </p>
                        </template>
                    </HelpToolTip>
                </div>
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.supportCost?.toFixed(2)]) }}
                </div>
            </v-col>

            <v-col cols="2">
                <span class="font-weight-bold">{{ $t('total') }}</span>
                <div class="font-weight-bold text-subtitle-1">
                    {{ $t('currency', [historicalBills?.total?.toFixed(2)]) }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import HelpToolTip from 'components/common/HelpTooltip'

    const HistoricalBillSummary = {
        name: 'HistoricalBillSummary',
        components: { HelpToolTip, },
        props: {
            historicalBills: {
                required: true,
                type: Object,
            },
        },
    }
    export default HistoricalBillSummary
</script>

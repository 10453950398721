<style lang="scss">
.details-card {
    padding: 24px;

    .details-title {
        color: $azure;
        margin-bottom: 10px;
    }
}
</style>
<template>
    <v-container
        fluid
        class="mt-2">
        <v-row>
            <v-col :cols="showXpandReplication ? 4 : 6">
                <div class="d-flex flex-column">
                    <ServiceDetails
                        :service="service"
                        :service-policies="servicePolicies"
                        :all-topologies="allTopologies"
                        :configuration-list="configs" />

                    <ServiceEndpoints
                        v-if="showEndpoints"
                        :service="service" />
                </div>
            </v-col>
            <v-col :cols="showXpandReplication ? 3 : 6">
                <div class="d-flex flex-column">
                    <SpendingDetails
                        :service="service"
                        :total-cost="totalCost"
                        :scu-hours="scuHours"
                        :components="components"
                        :instance-cost-per-hour="instanceCostPerHour"
                        :ssd-storage-cost-per-hour="ssdStorageCostPerHour"
                        :iops-cost-per-hour="iopsCostPerHour"
                        :scale-fabric-cost-per-hour="scaleFabricCostPerHour"
                        :hourly-total-cost="hourlyTotalCost"
                        :spending-data-un-available="spendingDataUnAvailable"
                        :max-scale-cost-per-hour="maxScaleCostPerHour"
                        :max-scale-redundancy-label="maxScaleRedundancyLabel"
                        :secondary-endpoint-cost-per-hour="secondaryEndpointCostPerHour"
                        :gp3-iops-cost-per-hour="gp3IopsCostPerHour"
                        :gp3-throughput-cost-per-hour="gp3ThroughputCostPerHour"
                        :gp3-iops-label="gp3IopsLabel"
                        :gp3-throughput-label="gp3ThroughputLabel"
                        :loading="isFetchingSpend" />

                    <MaintenanceDetails
                        :service-id="service.id"
                        :service-maintenance-window="service.maintenance_window ? service.maintenance_window : ''"
                        :is-power-tier-user="isPowerTierUser" />

                    <Configuration
                        v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                        :config-value="configValue" />
                </div>
            </v-col>
            <v-col
                v-if="showXpandReplication"
                cols="5">
                <XpandReplicationDetails
                    :service="service"
                    :replications="replications" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import ServiceDetails from 'components/serviceDetails/Details.vue'
    import { getReplicas } from 'utils/replication'
    import { getInstancesCosts, getIOPSCost, getSpendingByService, getStorageCost } from 'services/billings'
    import to from 'await-to-js'
    import {
        getTotalPricingForInstances,
        getTotalPricingForStorage,
        getTotalPricingForIOPS,
        getTotalPricingForScaleFabric,
        getMaxScaleRedundancyLabel,
        getPricingForEndpoint,
        getTotalPricingForGP3IOPS,
        getTotalPricingForGP3Throughput
    } from 'utils/billing'
    import {
        isStandAloneTopology,
        isSingleNodeAnalytics,
        isServerlessTopology,
        getNodeTypeForPricingAPI,
        hasMultipleEndpoints
    } from 'utils/service'
    import SpendingDetails from 'components/serviceDetails/Spending.vue'
    import MaintenanceDetails from 'components/serviceDetails/Maintenance.vue'
    import XpandReplicationDetails from 'components/serviceDetails/XpandReplication.vue'
    import ServiceEndpoints from 'components/serviceDetails/ServiceEndpoints.vue'
    import Configuration from 'components/serviceDetails/Configuration.vue'
    import { isPowerTier } from 'utils/organization'

    export default {
        name: 'Details',
        components: {
            ServiceDetails,
            SpendingDetails,
            MaintenanceDetails,
            XpandReplicationDetails,
            ServiceEndpoints,
            Configuration,
        },
        data() {
            return {
                serviceDetailsData: [],
                totalCost: 0,
                scuHours: 0,
                components: [],
                instanceCostPerHour: '',
                ssdStorageCostPerHour: '',
                iopsCostPerHour: '',
                scaleFabricCostPerHour: '',
                hourlyTotalCost: '',
                spendingDataUnAvailable: false,
                maxScaleCostPerHour: '',
                maxScaleRedundancyLabel: '',
                secondaryEndpointCostPerHour: '',
                gp3IopsCostPerHour: '',
                gp3ThroughputCostPerHour: '',
                gp3IopsLabel: '',
                gp3ThroughputLabel: '',
                isFetchingSpend: false,
            }
        },
        computed: {
            ...mapGetters([
                'services',
                'getServiceById',
                'replication',
                'servicePolicies',
                'configs',
                'allTopologies',
                'currentTier',
                'endpointPricing',
                'gp3IopsCosts',
                'throughputCosts',
                'volumeTypes'
            ]),
            service() {
                return this.getServiceById(this.$route.params.id)
            },
            isServerless() {
                return isServerlessTopology(this.service?.topology)
            },
            showXpandReplication() {
                return Boolean(this.replications.length)
            },
            replications() {
                return getReplicas(this.services, this.service)
            },
            isPowerTierUser() {
                return isPowerTier(this.currentTier)
            },
            gp3Iops() {
                let type = this.volumeTypes.find(type => type.name == this.$config.iops.gp3)
                return type ? type.iops[0] : {}
            },
            configValue() {
                if (!this.allTopologies.length) return ''
                else if (this.service.config_id) {
                    return this.configs.find(config => config.id === this.service.config_id).name
                }
                const topologyId = this.allTopologies.find(topology => topology.name === this.service.topology).id
                const foundConfig = this.configs.find(config => config.topology_id === topologyId)
                return foundConfig ? foundConfig.name : ''
            },
            showEndpoints() {
                return this.$unleash.isFeatureEnabled('enable-portal-dual-endpoints') && !isServerlessTopology(this.service.topology)
            },
        },
        watch: {
            'service.endpoints': {
                handler(newVal, oldVal) {
                    // If there is change in endpoints count
                    // re calc the spending widget
                    if (newVal.length !== oldVal.length) {
                        this.setSpendingData()
                    }
                },
            },
        },
        methods: {
            ...mapActions(['fetchPoliciesForService', 'getConfigsList', 'getAllTopologies', 'fetchEndpointPricing', 'fetchVolumeTypes', 'fetchIOPSCost', 'fetchThroughputCost']),
            async setSpendingData() {
                // Fetch all dependencies and use them to populate values
                const hasIOPS = this.service?.storage_volume?.volume_type === this.$config.iops.io1
                const isGP3Selected = this.service?.storage_volume?.volume_type === this.$config.iops.gp3
                const { topology, provider, region, size: name, architecture: arch, nodes, maxscale_size: maxScaleSize, maxscale_nodes: maxScaleNodes, } = this.service
                const isAWSProvider = provider === this.$config.cloudProvider.AWS
                const isMaxScaleRedundancyEnabled = maxScaleNodes > 1
                let billingRequests = [
                    getSpendingByService(this.service.id),
                    getInstancesCosts({
                        topology,
                        provider,
                        region,
                        name,
                        ...(isAWSProvider && { arch, }),
                        node: getNodeTypeForPricingAPI(this.service.topology),
                    }),
                    getStorageCost({
                        topology,
                        provider,
                        region,
                    })
                ]
                if (hasIOPS) {
                    billingRequests.push(getIOPSCost({
                        topology,
                        provider,
                        region,
                    }))
                }
                if (isMaxScaleRedundancyEnabled) {
                    billingRequests.push(
                        getInstancesCosts({
                            topology,
                            provider,
                            region,
                            name: maxScaleSize,
                            ...(isAWSProvider && { arch, }),
                            node: maxScaleNodes,
                        }))
                }
                this.isFetchingSpend = true
                const [error, response] = await to(Promise.all(billingRequests))
                if (error) {
                    this.spendingDataUnAvailable = true
                    this.isFetchingSpend = false
                    return
                }

                const [totalSpending, instanceCost, storageCost, ...iopsCostAndORMaxScaleCost] = response
                if (this.isServerless) {
                    this.scuHours = totalSpending?.components?.find(component => component.usageUnit?.toLowerCase()?.includes('scu'))?.quantity ?? 0
                    this.components = totalSpending?.components || []
                }
                this.isFetchingSpend = false
                let iopsCost, maxScaleCost
                if (hasIOPS && isMaxScaleRedundancyEnabled) {
                    [iopsCost, maxScaleCost] = iopsCostAndORMaxScaleCost
                } else {
                    if (hasIOPS) iopsCost = iopsCostAndORMaxScaleCost[0]
                    else maxScaleCost = iopsCostAndORMaxScaleCost[0]
                }
                const totalCost = this.$typy(totalSpending.estimate).safeObject ? totalSpending.estimate : 0
                this.totalCost = totalCost

                if (this.isServerless) return

                const instanceTotalPrice = getTotalPricingForInstances(topology, name, nodes, instanceCost)
                this.instanceCostPerHour = this.$t('perHour', { hour: this.$t('currency', [instanceTotalPrice?.toFixed(5)]), })

                const storageTotalPrice = getTotalPricingForStorage(topology, this.service.storage_volume.size, nodes, storageCost[0])
                this.ssdStorageCostPerHour = this.$t('perHour', { hour: this.$t('currency', [storageTotalPrice?.toFixed(5)]), })

                let iopsTotalPrice = 0
                if (hasIOPS) {
                    const iops = this.service?.storage_volume?.iops / this.service?.storage_volume?.size
                    iopsTotalPrice = getTotalPricingForIOPS(
                        topology,
                        this.service?.storage_volume?.size,
                        nodes,
                        iops,
                        iopsCost[0]
                    )
                    this.iopsCostPerHour = this.$t('perHour', { hour: this.$t('currency', [iopsTotalPrice?.toFixed(5)]), })
                }

                const hasScaleFabric = !isStandAloneTopology(topology) && !isSingleNodeAnalytics(topology, nodes)

                let scaleFabricTotal = 0
                if (hasScaleFabric) {
                    scaleFabricTotal = getTotalPricingForScaleFabric(topology, name, nodes, instanceCost)
                    this.scaleFabricCostPerHour = this.$t('perHour', { hour: this.$t('currency', [scaleFabricTotal?.toFixed(5)]), })
                }
                let maxScaleTotalPrice = 0
                if (isMaxScaleRedundancyEnabled) {
                    maxScaleTotalPrice = getTotalPricingForInstances(topology, maxScaleSize, maxScaleNodes, maxScaleCost)
                    this.maxScaleCostPerHour = this.$t('perHour', { hour: this.$t('currency', [maxScaleTotalPrice?.toFixed(5)]), })
                    this.maxScaleRedundancyLabel = getMaxScaleRedundancyLabel(maxScaleNodes, maxScaleSize)
                }
                let gp3TotalPrice = 0
                if (isGP3Selected) {
                    const { iops, throughput, } = this.service?.storage_volume
                    await this.fetchVolumeTypes(this.service.provider)
                    const { throughput_range_min: throughputMin, total_iops_range_min: iopsMin, } = this.gp3Iops
                    if (iops > iopsMin) {
                        await this.fetchIOPSCost({
                            topology,
                            provider,
                            region,
                        })
                        this.gp3IopsLabel = (`${iops} ${this.$t('provisioned')} ${this.$t('billings.iops')}`)
                        iopsCost = getTotalPricingForGP3IOPS(topology, iops - iopsMin, nodes, this.gp3IopsCosts)
                        gp3TotalPrice += iopsCost
                        this.gp3IopsCostPerHour = this.$t('perHour', { hour: this.$t('currency', [iopsCost?.toFixed(5)]), })
                    }
                    if (throughput > throughputMin) {
                        await this.fetchThroughputCost({
                            topology,
                            provider,
                            region,
                        })
                        this.gp3ThroughputLabel = (`${throughput} ${this.$t('mbPerSec')} ${this.$t('launchService.storageThroughput').toLowerCase()}`)
                        const throughputCost = getTotalPricingForGP3Throughput(topology, throughput - throughputMin, nodes, this.throughputCosts)
                        gp3TotalPrice += throughputCost
                        this.gp3ThroughputCostPerHour = this.$t('perHour', { hour: this.$t('currency', [throughputCost?.toFixed(5)]), })
                    }
                }

                const hasDualEndpoint = hasMultipleEndpoints(this.service.endpoints)
                const endpointPricing = this.endpointPricing.find(priceObj => priceObj.provider === this.service.provider)
                let secondaryEndpointPrice = 0
                this.secondaryEndpointCostPerHour = ''
                if (hasDualEndpoint && endpointPricing) {
                    secondaryEndpointPrice = getPricingForEndpoint(endpointPricing.prices, 'hour')
                    this.secondaryEndpointCostPerHour = this.$t('perHour', { hour: this.$t('currency', [secondaryEndpointPrice?.toFixed(5)]), })
                }

                const total = instanceTotalPrice + storageTotalPrice + iopsTotalPrice + scaleFabricTotal + maxScaleTotalPrice + gp3TotalPrice + secondaryEndpointPrice
                this.hourlyTotalCost = this.$t('perHour', { hour: this.$t('currency', [total?.toFixed(4)]), })
            },
        },
        async mounted() {
            const { provider, region, } = this.service
            await Promise.all([
                this.setSpendingData(),
                this.$lodash.isEmpty(this.allTopologies) && this.getAllTopologies(),
                this.$lodash.isEmpty(this.configs) && this.getConfigsList(),
                this.fetchPoliciesForService(this.service.id),
                this.fetchEndpointPricing({ provider, region, })
            ])
        },
    }
</script>

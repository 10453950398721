import to from 'await-to-js'
import {
    fetchServices,
    toggleServiceState,
    scaleServiceNodes,
    deleteService,
    scaleServiceNodesUpDown,
    createReplication,
    fetchReplicas,
    deleteReplication,
    scaleStorageAndIOPs,
    setMaintenanceWindow,
    attachPrivateConnect,
    removePrivateConnectProject,
    disconnectPrivateConnect,
    removeSecondaryEndpoint,
    addSecondaryEndpoint
} from 'services/dbServices'
import {
    fetchTopologies,
    mapTopologyStatus,
    fetchTopologyServers,
    mapTopologyServers
} from 'services/observability'
import { sortArrayObjectsAlphabetically } from 'helpers'
import { isStandAloneTopology } from 'utils/service'
import { getServiceProgressMessages } from 'services/notifications'
import { camelize } from 'helpers/camelCase'

export default {
    state: {
        services: [],
        servicesError: false,
        servicesLoading: false,
        servicesInitialized: false,
        interval: null,
        stateChangeError: null,
        stateChangeSuccess: null,
        storageScaleError: null,
        storageScaleSuccess: null,
        nodeScaleError: null,
        nodeScaleSuccess: null,
        deleteServiceError: null,
        deleteServiceSuccess: null,
        nodesUpDownScaleError: null,
        nodesScaleUpDownSuccess: null,
        replications: null,
        createReplicationSuccess: null,
        topologiesData: null,
        deleteReplicationSuccess: null,
        maintenanceWindowSuccess: null,
        removeProjectError: null,
        disconnectProjectError: null,
        serviceProgress: {},
        serviceProgressUpdateCounter: 0,
    },
    mutations: {
        setServices(state, services) {
            state.services = services
        },
        setServicesInitialized(state, payload) {
            state.servicesInitialized = payload
        },
        setServicesLoading(state, payload) {
            state.servicesLoading = payload
        },
        setServicesError(state, payload) {
            state.servicesError = payload
        },
        setServicesInterval(state, intervalId) {
            state.interval = intervalId
        },
        setServiceStateChangeError(state, error) {
            state.stateChangeError = error
        },
        setServiceStateChangeSuccess(state) {
            state.stateChangeSuccess = true
        },
        setStorageScaleError(state, error) {
            state.storageScaleError = error
        },
        setStorageScaleSuccess(state) {
            state.storageScaleSuccess = true
        },
        setNodesScaleError(state, error) {
            state.nodeScaleError = error
        },
        setNodesScaleSuccess(state) {
            state.nodeScaleSuccess = true
        },
        setDeleteServiceError(state, error) {
            state.deleteServiceError = error
        },
        setDeleteServiceSuccess(state) {
            state.deleteServiceSuccess = true
        },
        setNodesUpDownScaleError(state, error) {
            state.nodesUpDownScaleError = error
        },
        setNodesScaleUpDownSuccess(state) {
            state.nodesScaleUpDownSuccess = true
        },
        setReplication(state, payload) {
            state.replication = payload
        },
        setCreateReplicationSuccess(state, payload) {
            state.createReplicationSuccess = payload
        },
        setDeleteReplicationSuccess(state, payload) {
            state.deleteReplicationSuccess = payload
        },
        // TODO: This data can be set within "services", need to check if it can be done
        setTopologiesData(state, payload) {
            state.topologiesData = payload
        },
        setMaintenanceWindowSuccess(state, payload) {
            state.maintenanceWindowSuccess = payload
        },
        setRemoveProjectError(state, error){
            state.removeProjectError = error
        },
        setProjectDisconnectError(state, error) {
            state.disconnectProjectError = error
        },
        setServiceProgress(state, payload){
            if(!state.serviceProgress[payload.serviceId]){
                state.serviceProgress[payload.serviceId] = {}
            }
            state.serviceProgress[payload.serviceId] = payload.steps
            // Incrementing the progress update counter
            // this is a work around to avoid costly operation
            // to deep check the serviceProgress State
            // which will get bigger and bigger
            state.serviceProgressUpdateCounter++
        },
    },
    actions: {
        async fetchServices({ commit, }) {
            commit('setServicesLoading', true)
            try {
                let services = await fetchServices()
                services.forEach(service => service.servers = [])

                const [errorTopologies, topologiesData = {}] = await to(fetchTopologies())
                const [errorServers, serversData = []] = await to(fetchTopologyServers())
                if (!errorTopologies) {
                    commit('setTopologiesData', topologiesData.topologies)
                    services = mapTopologyStatus(services, topologiesData.topologies)
                }
                if (!errorServers) services = mapTopologyServers(services, serversData)

                if (!this.servicesInitialized) commit('setServicesInitialized', true)
                commit('setServices', services)
                commit('setServicesError', false)
            } catch (error) {
                if (!this.servicesInitialized) commit('setServicesInitialized', true)
                commit('setServicesError', true)
            }
            commit('setServicesLoading', false)
        },
        startServicesInterval({ dispatch, commit, state, }, seconds) {
            clearInterval(state.interval)
            dispatch('fetchServices')

            const intervalId = setInterval(() => {
                dispatch('fetchServices')
            }, seconds * 1000)

            commit('setServicesInterval', intervalId)
        },
        stopServicesInterval({ state, commit, }) {
            clearInterval(state.interval)
            commit('setServicesInterval', null)
        },
        async startStopService({ commit, dispatch, }, { serviceId, serviceState, }) {
            const [error, response] = await to(toggleServiceState(serviceId, serviceState))

            if (error) {
                commit('setServiceStateChangeError', error)
            } else {
                dispatch('fetchServices')
                commit('setServiceStateChangeSuccess', response || true)
                commit('setServiceStateChangeError', false)
            }
        },
        async scaleNodes({ commit, dispatch, }, { serviceId, nodes, }) {
            const [error, response] = await to(scaleServiceNodes(serviceId, nodes))

            if (error) {
                commit('setNodesScaleError', error)
            } else {
                // Update the services post success
                dispatch('fetchServices')
                commit('setNodesScaleSuccess', response || true)
                commit('setNodesScaleError', false)
            }
        },
        async deleteService({ commit, dispatch, }, { serviceId, }) {
            commit('setServicesLoading', true)
            const [error, response] = await to(deleteService(serviceId))
            if (error) {
                commit('setDeleteServiceError', error)
                commit('setServicesLoading', false)
            } else {
                // Update the services post success
                dispatch('fetchServices')
                commit('setDeleteServiceSuccess', response || true)
                commit('setDeleteServiceError', false)
            }
        },
        async scaleNodesUpDown({ commit, dispatch, }, { serviceId, size, }) {
            const [error, response] = await to(scaleServiceNodesUpDown(serviceId, size))

            if (error) {
                commit('setNodesUpDownScaleError', error)
            } else {
                // Update the services post success
                dispatch('fetchServices')
                commit('setNodesScaleUpDownSuccess', response || true)
                commit('setNodesUpDownScaleError', false)
            }
        },
        async getReplication({ commit, }, { serviceId, }) {
            commit('setServicesLoading', true)
            const [error, response] = await to(fetchReplicas(serviceId))
            if (error) {
                commit('setServicesLoading', false)
            } else {
                commit('setReplication', response)
            }
            commit('setServicesLoading', false)
        },
        async createReplication({ commit, }, { serviceId, primaryServiceId, replicationType, }) {
            commit('setServicesLoading', true)
            const [error, response] = await to(createReplication(serviceId, primaryServiceId, replicationType))
            if (error) {
                commit('setServicesLoading', false)
                commit('setCreateReplicationSuccess', false)
            } else {
                commit('setCreateReplicationSuccess', response || true)
            }
            commit('setServicesLoading', false)
        },
        async deleteReplicas({ commit, }, { serviceId, }) {
            commit('setServicesLoading', true)
            const [error, response] = await to(deleteReplication(serviceId))
            if (error) {
                commit('setServicesLoading', false)
                commit('setDeleteReplicationSuccess', false)
            } else {
                commit('setDeleteReplicationSuccess', response || true)
            }
            commit('setServicesLoading', false)
        },
        async scaleStorage({ commit, dispatch, }, { serviceId, storageValues, }) {
            const [error, response] = await to(scaleStorageAndIOPs(serviceId, storageValues))

            if (error) {
                commit('setStorageScaleError', error)
            } else {
                // Update the services post success
                dispatch('fetchServices')
                commit('setStorageScaleSuccess', response || true)
                commit('setStorageScaleError', false)
            }
        },
        async setMaintenanceWindowForService({ commit, dispatch, }, { serviceId, windowObj, }) {
            commit('setServicesLoading', true)
            const [error, response] = await to(setMaintenanceWindow(serviceId, windowObj))
            if (!error) {
                commit('setMaintenanceWindowSuccess', response || true)
                // Update the services post success
                dispatch('fetchServices')
            }
            commit('setServicesLoading', false)
        },
        async setPrivateConnect({ commit, dispatch, }, { serviceId, projects, provider, }) {
            commit('setServicesLoading', true)
            const [error] = await to(attachPrivateConnect(serviceId, projects, provider))
            if (!error) {
                // Update the services post success
                dispatch('fetchServices')
            }
            commit('setServicesLoading', false)
        },
        async removePrivateConnect({ commit, dispatch, }, { serviceId, existingProjects, provider, removeProjectId, }) {
            commit('setServicesLoading', true)
            const [error] = await to(removePrivateConnectProject(serviceId, removeProjectId, existingProjects, provider))
            if (error) {
                commit('setRemoveProjectError', error)
            } else {
                // Update the services post success
                dispatch('fetchServices')
                commit('setRemoveProjectError', false)
            }
            commit('setServicesLoading', false)
        },
        async disconnectPrivateConnectService({ commit, dispatch, },{ serviceId, }) {
            const [error] = await to(disconnectPrivateConnect(serviceId))
            if (error) {
                commit('setProjectDisconnectError', error)
            } else {
                // Update the services post success
                dispatch('fetchServices')
                commit('setProjectDisconnectError', false)
            }
        },
        async fetchServiceProgress({commit,}, serviceId){
            const [error, response] = await to(getServiceProgressMessages(serviceId))
            if (error) {
                commit('setProjectDisconnectError', error)
            } else {
                commit('setServiceProgress', camelize(response))
                commit('setProjectDisconnectError', false)
            }
        },
        async removeEndpoint({dispatch,}, {serviceId, endpointName, }){
            const [error] = await to(removeSecondaryEndpoint(serviceId, endpointName))
            if (!error) {
                dispatch('fetchServices')
            }
        },
        async addEndpoint({dispatch,}, {serviceId, endpointName, }){
            const [error] = await to(addSecondaryEndpoint(serviceId, endpointName))
            if (!error) {
                dispatch('fetchServices')
            }
        },
    },
    getters: {
        getServiceById: (state) => (id) => state.services.find(service => service.id === id),
        getServiceEndpointPorts(state, getters) {
            return function (serviceId, endpointName) {
                const service = getters.getServiceById(serviceId)
                if (!service || !service.endpoints) return []
                const endpoint = service.endpoints.find(endpoint => endpoint.name === endpointName)
                if (!endpoint || !endpoint.ports) return []
                return endpoint.ports
            }
        },
        getServerById(state) {
            return function (id) {
                for (let i = 0; i < state.services.length; i++) {
                    const service = state.services[i]
                    for (let i2 = 0; i2 < service.servers.length; i2++) {
                        const server = service.servers[i2]
                        if (server.id === id) return server
                    }
                }
                return null
            }
        },
        services: state => state.services,
        servicesLoading: state => state.servicesLoading,
        servicesInitialized: state => state.servicesInitialized,
        servicesError: state => state.servicesError,
        servicesHaveMaxScale: state => state.services.filter(
            service =>
                !isStandAloneTopology(service.topology)
        ).sort(sortArrayObjectsAlphabetically('name')),
        stateChangeSuccess: state => state.stateChangeSuccess,
        stateChangeError: state => state.stateChangeError,
        storageScaleError: state => state.storageScaleError,
        storageScaleSuccess: state => state.storageScaleSuccess,
        nodeScaleError: state => state.nodeScaleError,
        nodeScaleSuccess: state => state.nodeScaleSuccess,
        deleteServiceError: state => state.deleteServiceError,
        deleteServiceSuccess: state => state.deleteServiceSuccess,
        nodesUpDownScaleError: state => state.nodesUpDownScaleError,
        nodesScaleUpDownSuccess: state => state.nodesScaleUpDownSuccess,
        replication: state => state.replication,
        createReplicationSuccess: state => state.createReplicationSuccess,
        topologiesData: state => state.topologiesData,
        deleteReplicationSuccess: state => state.deleteReplicationSuccess,
        maintenanceWindowSuccess: state => state.maintenanceWindowSuccess,
        removeProjectError: state => state.removeProjectError,
        disconnectProjectError: (state) => state.disconnectProjectError,
        serviceProgress: (state) => state.serviceProgress,
        serviceProgressUpdateCounter: (state) => state.serviceProgressUpdateCounter,
        getServiceProgressById: (state) => (id) => state.serviceProgress[id],
    },
}

<template>
    <InitializingMessage v-if="!servicesInitialized" />
    <div
        v-else
        class="d-flex mt-4">
        <Navigation
            :service="service"
            :server="selectedServer" />
        <div class="flex-grow-1">
            <div
                v-if="errorMsg"
                class="text-h5 text-center">
                {{ errorMsg }}
            </div>
            <Dashboard
                v-else-if="!isInitializing"
                :dashboard="selectedDashboard"
                :service="service"
                :server="selectedServer"
                :links="mapDashboardLinks(availableDashboards)"
                @dashboardChange="changeDashboard" />
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import InitializingMessage from 'components/messages/Initializing.vue'
    import Navigation from 'components/monitoring/Navigation.vue'
    import Dashboard from 'components/monitoring/Dashboard.vue'
    import monitoringDashboards from 'data/monitoring/dashboards/index.js'

    export default {
        components: {
            InitializingMessage,
            Navigation,
            Dashboard,
        },
        data () {
            return {
                isInitializing: true,
            }
        },
        computed: {
            ...mapGetters(['services', 'servicesError', 'servicesInitialized', 'regions', 'regionErrors']),
            selectedServer () {
                return this.service?.servers?.find(server => server.id === this.$route.params.serverId) || {}
            },
            service () {
                return this.services.find(topology => topology.id === this.$route.params.id) || {}
            },
            availableDashboards () {
                const { topology, } = this.service
                const { serverRole, } = this.selectedServer
                return monitoringDashboards
                    .filter(({ topologies, }) => topologies.indexOf(topology) !== -1)
                    .filter(({ type, }) => {
                        if (serverRole) return serverRole === type
                        return type === 'service-overview'
                    })
                    .sort((a, b) => a.priority - b.priority)
            },
            selectedDashboard () {
                const { dashboardId, } = this.$route.params
                const dashboardIndex = this.availableDashboards.findIndex(({ id, }) => id === dashboardId)
                return this.availableDashboards[dashboardIndex !== -1 ? dashboardIndex : 0]
            },
            isDashboardValid () {
                // TODO: compare against dashboard schema
                return true
            },
            errorMsg () {
                if (this.regionErrors) return this.$t('monitoring.errors.noRegions')
                if (!this.availableDashboards.length) return this.$t('monitoring.errors.noDashboards')
                return null
            },
        },
        methods: {
            ...mapActions(['getRegions']),
            mapDashboardLinks (dashboards) {
                return dashboards.map(({ id, title, }) => ({ id, title, }))
            },
            changeDashboard (dashboardId) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        dashboardId,
                    },
                })
            },
        },
        async created () {
            try {
                await this.getRegions()
            } catch (_) {
                //
            }
            this.isInitializing = false
        },
    }
</script>

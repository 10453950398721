<template>
    <v-expansion-panel
        id="optionsPanel"
        :disabled="isDisabled">
        <AccordianPanelHeader
            :title="getLocaleFor('additionalOptions')">
            <template #tooltip-content>
                <div>{{ $t('launchService.additionalOptionsInfo') }}</div>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content>
            <div
                v-for="({ name, type, value, props, label, hint}, i) in options"
                :key="i"
                class="ml-1 my-2">
                <div v-if="type === 'checkbox'">
                    <v-checkbox
                        class="font-weight-bold mb-0 pb-0"
                        :label="getLocaleFor(label)"
                        @change="(value) => onOptionChange(name, value)" />
                    <div class="ml-8 pt-0 mt-n3 text-caption">
                        {{ getLocaleFor(hint) }}
                    </div>
                </div>

                <div v-else-if="type === 'range'">
                    <div class="mt-4 mb-2 text-subtitle-1 font-weight-bold">
                        {{ getLocaleFor(label) }}
                    </div>
                    <v-range-slider
                        :value="value"
                        :min="props.min || 0"
                        :max="props.max || 10"
                        hide-details
                        @change="(value) => onOptionChange(name, value)">
                        <template #prepend>
                            <div
                                class="pt-1 text-body-2"
                                style="white-space: nowrap;">
                                <span class="font-weight-bold">Min:</span> {{ value[0] }} SCUs
                            </div>
                        </template>
                        <template #append>
                            <div
                                class="text-body-2"
                                style="white-space: nowrap;">
                                <span class="font-weight-bold">Max:</span> {{ value[1] }} SCUs
                            </div>
                        </template>
                    </v-range-slider>
                    <div class="mt-2 text-caption">
                        {{ getLocaleFor(hint) }}
                    </div>
                </div>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    export default {
        name: 'LaunchServiceOptionsSelection',
        components: { AccordianPanelHeader, },
        props: {
            options: {
                type: Array,
                default: () => [],
            },
            getLocaleFor: {
                type: Function,
                default: (string) => string,
            },
            isDisabled: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            onOptionChange(optionName, value) {
                this.$emit('optionChange', {
                    optionName,
                    value,
                })
            },
        },
    }
</script>

import dbServices from 'api/dbServices'

export default {
    state: {
        allowlist: [],
        allowlistLoading: false,
        allowlistUpdatingAddress: null,
    },
    mutations: {
        setAllowList(state, allowlist) {
            state.allowlist = allowlist
        },
        setAllowListLoading(state, payload) {
            state.allowlistLoading = payload
        },
        setAllowlistUpdatingAddress(state, payload) {
            state.allowlistUpdatingAddress = payload
        },
    },
    actions: {
        async fetchAllowList({ commit,}, serviceId) {
            commit('setAllowList', [])
            commit('setAllowListLoading', true)
            try {
                const { data, } = await dbServices.get(`services/${serviceId}/security/allowlist`)
                commit('setAllowList', data.length && data[0].allow_list ? data[0].allow_list : [])
            } catch (error) {
                commit('setAllowListLoading', false)
            }
            commit('setAllowListLoading', false)
        },

        async updateAddressToAllowList({ commit, dispatch,}, { serviceId, allowList, }) {
            commit('setAllowlistUpdatingAddress', true)
            try {
                const { data, } = await dbServices.put(`services/${serviceId}/security/allowlist`, allowList)
                commit('setAllowList',  data.length && data[0].allow_list ? data[0].allow_list : allowList)
                dispatch('fetchServices', null, { root: true, })
            } catch (error) {
                commit('setAllowlistUpdatingAddress', null)
            }
            commit('setAllowlistUpdatingAddress', false)
        },
    },
    getters: {
        allowlist: (state) => state.allowlist,
        allowlistLoading: (state) => state.allowlistLoading,
        allowlistUpdatingAddress: (state) => state.allowlistUpdatingAddress,
    },
}

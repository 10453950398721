<template>
    <div class="mt-5">
        <alerts :preselected-services="selectedServices" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Alerts from 'components/alerts/Alerts'

    export default {
        name: 'ServiceAlerts',
        components: {
            'alerts': Alerts,
        },
        computed: {
            ...mapGetters(['services']),
            selectedServices() {
                return this.services?.find(service=> service.id === this.$route.params.id)?.servers.map(server=> server.dataSourceId)
            },
        },
    }
</script>

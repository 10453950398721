import copilotAPI from 'api/copilot.js'
import { camelize, convertToSnakeCase } from 'helpers/index.js'
import copilotConfig from 'config/copilot.js'

const defaultStarterQuestions = [
    { text: 'Describe yourself.', prompt: 'Describe yourself.', },
    { text: 'Sample questions you can help answer.', prompt: 'Provide a list of sample questions you can help answer.', }
]

function mapDatasources(datasources) {
    return datasources.map(datasource => {
        return {
            ...datasource,
            schemas: [],
        }
    })
}

function mapAgent(agent) {
    const systemAgent = Object.entries(copilotConfig.SYSTEM_AGENTS).find(([, value]) => {
        return value.id === agent.id
    })
    // TODO: Temporary hardcoded in the UI until API support is there
    const starterQuestions = systemAgent && systemAgent[1] ? systemAgent[1].starterQuestions : defaultStarterQuestions
    return {
        ...agent,
        context: null,
        queries: [],
        starterQuestions,
        hasMoreInfo: isCopilotAgent('data', agent.id) || isCopilotAgent('dba', agent.id),
    }
}

function mapAgents(agents) {
    return agents.map(mapAgent)
}

function mapChatSessions(chatSessions) {
    return chatSessions.map(chatSession => {
        return {
            ...chatSession,
            entries: [],
        }
    })
}

function mapQuotas(quotas) {
    return quotas.map(quota => {
        return {
            ...quota,
            usagePercent: (quota.usage / quota.value) || 0,
        }
    })
}

export function isCopilotAgent(type, agentId) {
    return copilotConfig.SYSTEM_AGENTS[type]?.id === agentId
}

export async function getAgents (noMapping) {
    return copilotAPI.get('agent/').then(response => camelize(noMapping ? response.data : mapAgents(response.data)))
}

export async function createAgent (payload) {
    return copilotAPI.post('agent/', convertToSnakeCase(payload)).then(response => camelize(mapAgent(response.data)))
}

export async function editAgent (agentId, data) {
    return copilotAPI.patch(`agent/${agentId}`, data).then(response => camelize(response.data))
}

export async function removeAgent (agentId) {
    return copilotAPI.delete(`agent/${agentId}`).then(response => camelize(response.data))
}

export async function generateAgentContext (agentId) {
    return copilotAPI.post(`agent/${agentId}/generate`).then(response => camelize(response.data))
}

export async function rebuildAgentContext (agentId) {
    return copilotAPI.post(`agent/${agentId}/rebuild`).then(response => camelize(response.data))
}

export async function getQueries (agentId, type) {
    return copilotAPI.get('query/', convertToSnakeCase({ agentId, type, })).then(response => camelize(response.data))
}

export async function createQuery (payload) {
    return copilotAPI.post('query/', convertToSnakeCase(payload)).then(response => camelize(response.data))
}

export async function updateQuery (queryId, payload) {
    return copilotAPI.patch(`query/${queryId}`, convertToSnakeCase(payload)).then(response => camelize(response.data))
}

export async function removeQuery (queryId) {
    return copilotAPI.delete(`query/${queryId}`).then(response => camelize(response.data))
}

export async function getContext (contextId) {
    return copilotAPI.get(`context/${contextId}`).then(response => camelize(response.data))
}

export async function createContext (datasourceId, schemaName) {
    return copilotAPI.post('context/', convertToSnakeCase({ datasourceId, name: schemaName, })).then(response => camelize(response.data))
}

export async function removeContext (contextId) {
    return copilotAPI.delete(`context/${contextId}`).then(response => camelize(response.data))
}

export async function removeContextTable (contextId, tableId) {
    return copilotAPI.delete(`context/${contextId}/table/${tableId}`).then(response => camelize(response.data))
}

export async function addContextTable (contextId, table) {
    return copilotAPI.post(`context/${contextId}/table`, { name: table, }).then(response => camelize(response.data))
}

export async function editContextTable (contextId, tableId, tableDescription) {
    return copilotAPI.patch(`context/${contextId}/table/${tableId}`, { description: tableDescription, }).then(response => camelize(response.data))
}

export async function editContextTableColumns (contextId, tableId, columns) {
    return copilotAPI.put(`context/${contextId}/table/${tableId}/columns`, convertToSnakeCase(columns)).then(response => camelize(response.data))
}

export async function getDatasources () {
    return copilotAPI.get('data-source/').then(response => camelize(mapDatasources(response.data)))
}

export async function getDatasource (datasourceId) {
    return copilotAPI.get(`data-source/${datasourceId}`).then(response => camelize(response.data))
}

export async function addDatasource (datasource) {
    return copilotAPI.post('data-source/', datasource).then(response => camelize(response.data))
}

export async function editDatasource (datasourceId, data) {
    return copilotAPI.patch(`data-source/${datasourceId}`, data).then(response => camelize(response.data))
}

export async function removeDatasource (datasourceId) {
    return copilotAPI.delete(`data-source/${datasourceId}`).then(response => camelize(response.data))
}

export async function getChatSessions () {
    return copilotAPI.get('session/').then(response => camelize(mapChatSessions(response.data)))
}

export async function getChatSession (chatSessionId) {
    return copilotAPI.get(`session/${chatSessionId}`).then(response => camelize(response.data))
}

export async function removeChatSession (chatSessionId) {
    return copilotAPI.delete(`session/${chatSessionId}`).then(response => camelize(response.data))
}

export async function clearChatSession (chatSessionId) {
    return copilotAPI.delete(`session/${chatSessionId}/entry`).then(response => camelize(response.data))
}

export async function createChatSession (agentId, config) {
    const payload = { agentId, }
    if (config) payload.config = config
    return copilotAPI.post('session/', convertToSnakeCase(payload)).then(response => camelize(response.data))
}

export async function sendChatMessage (prompt, chatSessionId) {
    return copilotAPI.post(`session/${chatSessionId}/entry`, { prompt, }).then(response => camelize(response.data))
}

export async function submitChatFeedback (feedback, entryId, chatSessionId) {
    return copilotAPI.patch(`session/${chatSessionId}/entry/${entryId}`, { feedback, }).then(response => camelize(response.data))
}

export async function analyseChatMessageQuery (chatSessionId, entryId) {
    return copilotAPI.get(`session/${chatSessionId}/entry/${entryId}/score`).then(response => camelize(response.data))
}

export async function getUsageQuotas () {
    return copilotAPI.get('quota/').then(response => camelize(mapQuotas(response.data)))
}

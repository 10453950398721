import als from 'api/als'
import common from 'config/common'
import i18n from 'plugins/i18n'
export default {
    state: {
        silences: [],
        setSilencesError: null,
    },
    mutations: {
        setSilences(state, silences) {
            state.silences = silences
        },
        setSilencesError(state, error) {
            state.setSilencesError = error
        },
    },
    actions: {
        async getSilences({ commit, }) {
            try {
                const { data, } = await als.get('silences')
                commit('setSilences', data)
                commit('setSilencesError', false)
            } catch (error) {
                commit('setSilencesError', error)
            }
        },
        async createSilence({ commit, dispatch, }, silenceBody) {
            try {
                await als.post('silences', silenceBody)
                dispatch('getSilences')
                commit('setSilencesError', false)
            } catch (error) {
                commit('setSilencesError', error)
            }
        },
        async deleteSilence({ commit, dispatch, }, silenceId) {
            try {
                await als.delete(`silences/${silenceId}`)
                dispatch('getSilences')
                commit('setSilencesError', false)
                commit('showMessage', { text: i18n.t('success.deleteSilence'), type: common.messageType.SUCCESS, })
            } catch (error) {
                commit('setSilencesError', error)
            }
        },
    },
    getters: {
        silences: state => state.silences,
        setSilencesError: state => state.setSilencesError,
    },
}

<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col
            cols="auto pa-0 wrapper-container"
            class="d-flex flex-column fill-height">
            <div class="mb-4 view-headline color text-navigation font-weight-light">
                {{ $t('logsArchive') }}
            </div>
            <LogsWithViewer
                v-if="$unleash.isFeatureEnabled('enable-portal-logs-with-viewer')"
                :servers="servers" />
            <Logs v-else />
        </v-col>
    </v-container>
</template>

<script>
    import to from 'await-to-js'
    import Logs from 'components/logs/Logs.vue'
    import LogsWithViewer from 'components/logs/LogsWithViewer.vue'
    import observability from 'api/observability'
    export default {
        name: 'LogsPage',
        components: { Logs, LogsWithViewer, },
        data() {
            return {
                servers: [],
            }
        },
        methods: {
            async fetchServers() {
                const [error, response] = await to(observability.get('logs/servers'))
                if (!error) {
                    this.servers = this.$typy(response, 'data.servers').safeArray
                }
            },
        },
        async created() {
            if(this.$unleash.isFeatureEnabled('enable-portal-logs-with-viewer'))
                await this.fetchServers()
        },
    }
</script>

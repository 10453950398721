<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="submitContact"
        :on-cancel="clearState"
        :min-width="776"
        :max-width="776"
        :on-close="clearState">
        <template #title>
            <div class="font-weight-light text-h5 pb-6">
                {{ dialogTitle }}
            </div>
        </template>
        <template #body>
            <div :class="['pt-6', 'pl-6', 'pr-6', {'disable-dialog': isSaving}]">
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.firstName') }}</label>
                        <v-text-field
                            v-model="firstName"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('billings.firstName')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="text-uppercase">{{ $t('billings.lastName') }}</label>
                        <v-text-field
                            v-model="lastName"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('billings.lastName')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <label class="required text-uppercase">{{ $t('email') }}</label>
                        <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            outlined
                            type="email"
                            required
                            :placeholder="$t('email')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <label class="text-uppercase">{{ $t('billings.phoneNumber') }}</label>
                        <VueTelInput
                            v-model="phone"
                            mode="auto"
                            @input="onPhoneInput" />
                    </v-col>
                </v-row>
                <v-divider class="mt-8 mb-8" />
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.address1') }}</label>
                        <v-text-field
                            v-model="addressLine1"
                            type="text"
                            required
                            outlined
                            :placeholder="$t('billings.address1')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="text-uppercase">{{ $t('billings.address2') }}</label>
                        <v-text-field
                            v-model="addressLine2"
                            type="text"
                            outlined
                            :placeholder="$t('billings.address2')"
                            dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.country') }}</label>
                        <v-select
                            v-model="currentCountry"
                            :items="sortedCountries"
                            item-text="name"
                            item-val="iso31662"
                            height="40"
                            :dense="true"
                            class="mt-0 select-usage"
                            outlined
                            return-object
                            @change="changeCountry" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.stateProvince') }}</label>
                        <v-select
                            v-model="currentState"
                            :items="sortedStates"
                            item-text="name"
                            item-val="iso31662"
                            height="40"
                            :dense="true"
                            class="mt-0 select-usage"
                            outlined
                            return-object
                            @change="changeState" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0 pb-0">
                        <label class="required text-uppercase">{{ $t('billings.city') }}</label>
                        <v-text-field
                            v-model="city"
                            outlined
                            type="text"
                            required
                            :placeholder="$t('billings.city')"
                            dense />
                    </v-col>
                    <v-col class="pt-0 pb-0">
                        <label class="text-uppercase">{{ $t('billings.zipCode') }}</label>
                        <v-text-field
                            v-model="postalCode"
                            outlined
                            type="text"
                            required
                            :placeholder="$t('billings.zipCode')"
                            dense />
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                :disabled="!isContactFormValid"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { VueTelInput } from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css'

    export default {
        name: 'ContactModal',
        components: {
            VueTelInput,
        },
        data() {
            return {
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
                id: null,
                isDialogOpen: false,
                isForEdit: false,
                isSaving: false,
                emailRules: this.$help.emailInputRules(false),
                addressLine1: null,
                addressLine2: '',
                country: 'US',
                state: null,
                city: null,
                postalCode: null,
                currentCountry: {},
                currentState: {},
                isPrimary: false,
            }
        },
        computed: {
            ...mapGetters([
                'savingContactSuccess',
                'billingCountries',
                'billingStates'
            ]),
            sortedCountries(){
                const clonedCountries = this.$lodash.cloneDeep(this.billingCountries)
                return clonedCountries.sort((a, b) => a.name.localeCompare(b.name))
            },
            sortedStates() {
                const clonedStates = this.$lodash.cloneDeep(this.billingStates)
                clonedStates.sort((a, b) => a.name.localeCompare(b.name))
                if (this.$typy(this.currentCountry, 'name').safeString) {
                    clonedStates.filter(state => state.country === this.currentCountry.name)
                }
                return clonedStates
            },
            isContactFormValid() {
                return !!this.firstName
                    && !!this.email
                    && this.isEmailValid
                    && !!this.addressLine1
                    && !!this.country
                    && !!this.state
                    && !!this.city
            },
            isEmailValid() {
                return this.emailRules[3](this.email) === true
            },
            dialogTitle() {
                if (this.isPrimary) {
                    return this.isForEdit ? this.$t('editPrimaryContact') : this.$t('addPrimaryContact')
                }
                return this.isForEdit ? this.$t('editBillingContact') : this.$t('addBillingContact')
            },
        },
        watch:{
            billingCountries(countries){
                this.setCountryAndState(countries)
            },
            billingStates(states){
                if(!this.state) return
                this.currentState = states.find(state => state.isoShort === this.state)
            },
        },
        methods: {
            ...mapActions([
                'fetchCountries',
                'fetchStatesForCountry',
                'fetchContacts',
                'addNewContact',
                'editContact'
            ]),
            async open({ mode, contact, }) {
                this.isDialogOpen = true
                this.isForEdit = mode === 'edit'
                this.isPrimary = contact?.type?.toLowerCase() === 'primary'
                if (this.isForEdit) {
                    this.firstName = contact.firstName
                    this.lastName = contact.lastName
                    this.phone = contact.phone
                    this.email = contact.email
                    this.id = contact.id
                    this.addressLine1 = contact.address?.addressLine1
                    this.addressLine2 = contact.address?.addressLine2
                    this.country = contact.address?.country
                    this.state = contact.address?.state
                    this.city = contact.address?.city
                    this.postalCode = contact.address?.postalCode
                }
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    await this.$nextTick()
                    this.setCountryAndState(this.billingCountries)
                }
            },
            clearState() {
                this.firstName = null
                this.lastName = null
                this.phone = null
                this.email = null
                this.id = null
                this.isForEdit = false
                this.isSaving = false
                this.isDialogOpen = false,
                this.addressLine1 = null
                this.addressLine2 = ''
                this.country = 'US'
                this.state = null
                this.city = null
                this.postalCode = null
                this.currentCountry = {}
                this.currentState = {}
                this.isPrimary = false
            },
            async saveContact(contact) {
                if (this.isForEdit) {
                    await this.editContact({contact, id: this.id,})
                } else {
                    await this.addNewContact(contact)
                }
            },
            async submitContact() {
                this.isSaving = true
                const contact = {
                    firstName: this.firstName,
                    email: this.email,
                    type: this.isPrimary ? 'PRIMARY' : 'BILLING',
                }
                if (this.lastName) {
                    contact.lastName = this.lastName
                }
                if (this.phone) {
                    contact.phone = this.phone
                }

                const address = {
                    addressLine1: this.addressLine1,
                    country: this.country,
                    state: this.state,
                    city: this.city,
                }
                if (this.addressLine2) {
                    address.addressLine2 = this.addressLine2
                }
                if (this.postalCode) {
                    address.postalCode = this.postalCode
                }

                contact.address = address

                await this.saveContact(contact)
                this.isSaving = false
                if (this.savingContactSuccess) {
                    this.clearState()
                    this.$store.commit('showMessage', { text: this.$t('billings.contactSavedSuccessfully'), type: '#03a128', })
                    await this.fetchContacts()
                }
            },
            changeCountry(data){
                this.country = data.iso31662
                this.fetchStatesForCountry({country: data.iso31662,})
            },
            changeState(data){
                this.state = data.isoShort
            },
            onPhoneInput(_, phoneObject) {
                this.phone = phoneObject.number
            },
            setCountryAndState(countries) {
                this.country = this.country || 'US'
                this.currentCountry = countries.find(country => country.iso31662 === this.country)
                this.fetchStatesForCountry({country: this.country,})
            },
        },
    }
</script>


import als from 'api/als'

export default {
    state: {
        tags: [],
        tagsError: null,
    },
    mutations: {
        setTags(state, payload) {
            state.tags = payload
        },
        setTagsError(state, payload) {
            state.tagsError = payload
        },
    },
    actions: {
        async getTags({ commit, }) {
            try {
                const { data, } = await als.get('tags')
                commit('setTags', data || [])
                commit('setTagsError', false)
            } catch (error) {
                commit('setTagsError', error)
            }
        },
        async createTag({ commit, dispatch,}, tagObject) {
            try {
                const { data, } = await als.post('tags', tagObject)
                if (data) {
                    await dispatch('getTags')
                }
            } catch (error) {
                commit('setTagsError', error)
            }
        },
    },
    getters: {
        tags: (state) => state.tags,
        tagsError: (state) => state.tagsError,
    },
}

<style lang="scss" scoped>
.v-expansion-panel-header {
    text-transform: none !important;
}

.opened {
    transform: rotate(90deg);
}

.d-contents {
    display: 'contents'
}
</style>
<template>
    <v-expansion-panels multiple>
        <v-expansion-panel
            v-for="(notification, i) in filteredNotifications"
            :key="i">
            <v-expansion-panel-header
                hide-actions
                class="pa-0 py-2 font-weight-light">
                <template #default="{ open }">
                    <div class="text-subtitle-1">
                        <v-icon
                            :class="['color text-primary', { opened: open }]">
                            mdi-chevron-right
                        </v-icon>
                        <v-icon
                            class="px-1 color text-primary"
                            size="32">
                            $vuetify.icons.emailCustom
                        </v-icon>
                        {{ notification.name }}
                    </div>
                    <tag-listing
                        class="d-contents"
                        :tags="filteredTags(notification)"
                        small-tags
                        disable-delete-tag
                        :show-tags-count="6"
                        :is-tag-without-label="true" />
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="px-8">
                <v-row class="pt-3">
                    <v-col
                        cols="4"
                        class="pa-0 font-weight-medium">
                        {{ $t('repeatInterval') }}
                    </v-col>
                    <v-col class="pa-0">
                        {{ notification.repeat_interval }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="4"
                        class="text-capitalize pa-0 font-weight-medium">
                        {{ $tc('channel') }}
                    </v-col>
                    <v-col class="pa-0">
                        {{ getChannelName(notification) }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="4"
                        class="pa-0 font-weight-medium">
                        {{ $t('to') }}
                    </v-col>
                    <v-col class="pa-0">
                        {{ getReceivers(notification) }}
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: 'NotificationsTriggered',
        props: {
            matchingTags: {
                type: Array,
                required: true,
            },
        },
        computed: {
            ...mapGetters(['notifications', 'tags', 'channels']),
            filteredNotifications() {
                let tagTexts = this.matchingTags.map(tag => tag.text)
                // Old logic displays 'Any server' for 'all/all'
                tagTexts = tagTexts.sort().join('--').replace(this.$t('anyServer'), this.$config.allTopologiesTag).toLowerCase().split('--')
                return tagTexts ?
                    this.notifications.filter(notification => {
                        const tagValues = this.filteredTags(notification).map(tag => tag.value.toLowerCase())
                        return tagTexts.every(text => tagValues.includes(text))
                    })
                    : []
            },
        },
        methods: {
            ...mapActions(['getNotifications', 'getTags']),
            filteredTags(notification) {
                return this.tags.filter(tag => notification.tags.includes(tag.id))
            },
            getChannel(notification) {
                return this.channels.find(({ id, }) => id === notification.channel_id)
            },
            getChannelName(notification) {
                return this.getChannel(notification) ? this.getChannel(notification).channel_name : ''
            },
            getReceivers(notification) {
                return this.getChannel(notification) ? this.getChannel(notification).receivers.map(rec => rec.email).join(', ') : ''
            },
        },
        created() {
            this.getNotifications()
            this.getTags()
            this.$store.dispatch('fetchChannels')
        },
    }
</script>

<style lang="scss" scoped>
.maintenance-window-width {
    width: 320px;
    max-width: 320px;
}
</style>
<template>
    <v-card
        outlined
        class="details-card">
        <v-card-title class="details-title text-body-2 d-flex justify-space-between text-uppercase">
            {{ $t('serviceDetailsTab.maintenance') }}
        </v-card-title>
        <v-card-text>
            <v-row class="text-caption color text-navigation">
                <v-col
                    cols="5"
                    class="px-3 py-0">
                    {{ $t('serviceDetailsTab.dateAndTime') }}
                </v-col>
                <v-col
                    v-if="inEditMode"
                    cols="7"
                    class="px-3 py-0">
                    <v-row>
                        <v-select
                            v-model="selectedMaintenanceWindow"
                            class="std std--dropdown select-dropdown maintenance-window-width"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list max-height-330' }"
                            :items="maintenanceWindowList"
                            item-value="display_name"
                            item-text="display_name"
                            height="36"
                            outlined
                            attach
                            single-line
                            required />
                        <div
                            class="color text-links pointer pa-3"
                            @click="save">
                            {{ $t('save') }}
                        </div>
                        <div
                            class="color text-links pointer pa-3"
                            @click="cancel">
                            {{ $t('cancel') }}
                        </div>
                    </v-row>
                </v-col>
                <v-col
                    v-else
                    class="px-3 py-0">
                    <v-row>
                        <span class="pa-3">{{ selectedMaintenanceWindow }}</span>
                        <div
                            v-if="isPowerTierUser"
                            class="color text-links pointer pa-3"
                            @click="change">
                            {{ $t('change') }}
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'Maintenance',
        props: {
            serviceId: {
                type: String,
                required: true,
            },
            serviceMaintenanceWindow: {
                type: String,
                required: true,
            },
            isPowerTierUser: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                selectedMaintenanceWindow: '',
                inEditMode: false,
            }
        },
        computed: {
            ...mapGetters(['maintenanceWindowSuccess', 'maintenanceWindowList']),
        },
        methods: {
            ...mapActions(['setMaintenanceWindowForService', 'fetchMaintenanceWindowList']),
            change() {
                this.inEditMode = true
            },
            async save() {
                const foundWindow = this.maintenanceWindowList.find(window => window.display_name === this.selectedMaintenanceWindow)
                await this.setMaintenanceWindowForService({
                    serviceId: this.serviceId, windowObj: {
                        name: foundWindow.name,
                    },
                })
                if (this.maintenanceWindowSuccess) this.inEditMode = false
            },
            cancel() {
                this.inEditMode = false
                this.setMaintenanceWindow()
            },
            setMaintenanceWindow() {
                if (this.serviceMaintenanceWindow.includes(this.$config.UTC)) this.selectedMaintenanceWindow = this.serviceMaintenanceWindow
                else {
                    const foundWindow = this.maintenanceWindowList.find(maintenanceWindow => maintenanceWindow.name === this.serviceMaintenanceWindow)
                    this.selectedMaintenanceWindow = foundWindow ? foundWindow.display_name : ''
                }
            },
        },
        async created() {
            if (this.serviceMaintenanceWindow.includes(this.$config.UTC)) this.selectedMaintenanceWindow = this.serviceMaintenanceWindow
            this.$lodash.isEmpty(this.maintenanceWindowList) && await this.fetchMaintenanceWindowList()
            this.setMaintenanceWindow()
        },
    }
</script>

<style lang="scss" scoped>
.grayed-out-color {
    color: $grayed-out
}
</style>
<template>
    <div class="pt-10">
        <div class="d-flex justify-space-between py-4">
            <span class="text-h6">{{ teamName }}</span>
            <v-btn
                outlined
                rounded
                color="primary"
                class="ml-4 text-capitalize"
                @click="openInvite">
                {{ $t('invite') }}
            </v-btn>
        </div>
        <v-data-table
            :headers="headers"
            :loading="usersLoading"
            :items="users"
            sort-desc
            class="data-table-full"
            :disable-pagination="true"
            :hide-default-footer="true"
            :no-data-text="noDataText">
            <template #item="{ item: user, }">
                <tr
                    v-if="showUser(user.status)"
                    :key="user.id"
                    :class="{'grayed-out-color' : getStatus(user) !== $config.USER_STATUS.ACTIVE}">
                    <td>
                        {{ getName(user) }}
                    </td>
                    <td>
                        {{ user.email }}
                    </td>
                    <td>
                        {{ getRole(user.roles) }}
                    </td>
                    <td class="text-capitalize d-flex align-center">
                        <span style="min-width: 100px">{{ $t(`userStatus.${getStatus(user)}`) }}</span>
                        <span
                            v-if="canCancelOrResend(getStatus(user))"
                            class="color text-links text-capitalize pl-4 pointer"
                            @click="cancelOrResendInvite(user)">
                            {{ cancelOrResendInviteText(getStatus(user)) }}
                        </span>
                    </td>
                    <td>
                        <actions-menu
                            v-if="showMenu(user)"
                            :selected-user="user" />
                    </td>
                </tr>
            </template>
        </v-data-table>
        <user-actions
            ref="inviteModal"
            :dialog-type="$config.DIALOG_TYPE.INVITE" />
        <user-actions
            ref="cancelModal"
            :selected-user="currentUser"
            :dialog-type="$config.DIALOG_TYPE.CANCEL" />
        <user-actions
            ref="resendModal"
            :selected-user="currentUser"
            :dialog-type="$config.DIALOG_TYPE.RESEND" />
    </div>
</template>

<script>
    import ActionsMenuVue from 'components/userManagement/ActionsMenu.vue'
    import { mapGetters } from 'vuex'
    import UserActionsModalVue from 'components/userManagement/UserActionsModal.vue'
    export default {
        name: 'UserTeam',
        components: {
            'user-actions': UserActionsModalVue,
            'actions-menu': ActionsMenuVue,
        },
        props: {
            users: {
                type: Array,
                required: true,
                default: () => [],
            },
            teamName: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                headers: [
                    { text: this.$t('name'), value: 'name', width: '20%', sortable: true, },
                    { text: this.$t('email'), value: 'email', width: '30%', sortable: true, },
                    { text: this.$t('role'), value: 'role', width: '25%', sortable: true, },
                    { text: this.$t('status'), value: 'status', width: '15%', sortable: true, },
                    { text: '', value: 'menu', width: '10%', sortable: false, }
                ],
                noDataText: '$vuetify.noDataText',
                currentUser: null,
            }
        },
        computed: {
            ...mapGetters(['usersLoading', 'roles', 'userData']),
        },
        methods: {
            openInvite() {
                this.$refs.inviteModal.open()
            },
            canCancelOrResend(status) {
                const { INVITED, PENDING, EXPIRED, } = this.$config.USER_STATUS
                return [INVITED, PENDING, EXPIRED].includes(status)
            },
            cancelOrResendInviteText(status) {
                const { EXPIRED, } = this.$config.USER_STATUS
                return status === EXPIRED ? this.$t('userManagementTab.resendInvitation') : this.$t('userManagementTab.cancelInvitation')
            },
            cancelOrResendInvite(user) {
                const { EXPIRED, } = this.$config.USER_STATUS
                this.currentUser = user
                user.status === EXPIRED ? this.$refs.resendModal.open() : this.$refs.cancelModal.open()
            },
            getStatus(user) {
                const { ACTIVE, DEACTIVATED, ACCEPTED, } = this.$config.USER_STATUS
                return !user.status || user.status === ACCEPTED ?
                    (user.is_active ? ACTIVE : DEACTIVATED)
                    : user.status
            },
            showMenu(user) {
                const { EXPIRED, ACCEPTED, } = this.$config.USER_STATUS
                return user.id !== this.userData.user_id && (!user.status || [EXPIRED, ACCEPTED].includes(user.status))
            },
            getName(user) {
                return user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : ''
            },
            getRole(roles) {
                if (!roles?.length) return ''
                return this.roles.find(role => role.id === roles[0]).name
            },
            showUser(status) {
                // Cancelled invitation entry not to be displayed
                // Accepted invites user to be fetched from 'users' getter
                const { CANCELLED, ACCEPTED, } = this.$config.USER_STATUS
                return ![CANCELLED, ACCEPTED].includes(status)
            },
        },
    }
</script>

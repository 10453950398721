<style lang="scss" scoped>
.accordian-title-wrapper {
    background-color: #f3f6f8;
}

.expansion-panel-icon {
    order: 0;
    margin-right: 10px;
    margin-left: 0px;
}

.expansion-panel-header {
    order: 1;
    font-size: 20px;
}

.v-expansion-panel--active span.expansion-panel-icon {
    transform: rotate(90deg);
}
</style>
<template>
    <v-expansion-panel-header
        class="accordian-title-wrapper"
        disable-icon-rotate>
        <template #actions>
            <!-- Keeping this just to override default vuetify icon -->
            <span></span>
        </template>
        <v-row>
            <v-col>
                <v-icon
                    size="20"
                    class="expansion-panel-icon">
                    $vuetify.icons.arrowRight
                </v-icon>
                <span
                    class="expansion-panel-header ft-azo-sans font-weight-medium">
                    {{ title }}
                </span>
                <HelpToolTip v-if="showToolTip">
                    <template #message>
                        <slot name="tooltip-content">
                        </slot>
                    </template>
                </HelpToolTip>
            </v-col>
        </v-row>
    </v-expansion-panel-header>
</template>
<script>
    import HelpToolTip from 'components/common/HelpTooltip'

    export default {
        name: 'AccordianPanelHeader',
        components: { HelpToolTip, },
        props: {
            title: {
                type: String,
                default: '',
            },
            showToolTip: {
                type: Boolean,
                default: true,
            },
        },
    }
</script>
